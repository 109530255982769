export default [
    {
        "title": "Fraudsters Used AI to Mimic CEO\u2019s Voice in Unusual Cybercrime Case",
        "issue": "Criminals used AI-based software to copy a German CEO and requested to transfer over $243,000 to a supplier",
        "year": "2019",
        "domain": "Business",
        "city": "London",
        "state": "England",
        "country": "England",
        "lat": "51.539236",
        "lng": "-0.14349",
        "is_good": "Harmful",
        "link": "https://www.wsj.com/articles/fraudsters-use-ai-to-mimic-ceos-voice-in-unusual-cybercrime-case-11567157402",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Who to Sue When a Robot Loses Your Fortune",
        "issue": "Hong Kong Samathur Li-Kin kan let automated platform manage fund and lost 20 million daily",
        "year": "2019",
        "domain": "Business",
        "city": "Victoria",
        "state": "Hong Kong",
        "country": "",
        "lat": "22.280302",
        "lng": "114.158777",
        "is_good": "Harmful",
        "link": "https://www.bloomberg.com/news/articles/2019-05-06/who-to-sue-when-a-robot-loses-your-fortune",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Amazon AI Recruiting  Bias",
        "issue": "Amazon AI Recruiting tool favored male applicants over female. The software downgraded resumes that had the word \"women\"",
        "year": "2018",
        "domain": "Business",
        "city": "Seattle",
        "state": "Washington",
        "country": "USA",
        "lat": "47.606209",
        "lng": "-122.332069",
        "is_good": "Harmful",
        "link": "https://medium.com/syncedreview/2018-in-review-10-ai-failures-c18faadf5983",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Amazon AI Recruiting  Scrapped",
        "issue": "The company realized its new system was not rating candidates for software developer jobs and other technical posts in a gender-neutral way.",
        "year": "2018",
        "domain": "Business",
        "city": "Seattle",
        "state": "Washington",
        "country": "USA",
        "lat": "47.606209",
        "lng": "-122.332069",
        "is_good": "Helpful",
        "link": "https://www.reuters.com/article/us-amazon-com-jobs-automation-insight/amazon-scraps-secret-ai-recruiting-tool-that-showed-bias-against-women-idUSKCN1MK08G",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Predictem",
        "issue": "Automating background checks for employees. Specifically offers background checks for babysitters using NLP/Computer vision. Delivers a \"risk assessment score\". Was under criticism for data privacy violations in 2018.",
        "year": "2019",
        "domain": "Business",
        "city": "Berkeley",
        "state": "California",
        "country": "USA",
        "lat": "37.871593",
        "lng": "-122.272743",
        "is_good": "Harmful",
        "link": "https://www.bestpractice.ai/studies/predictim_offers_trustworthy_background_checks_for_babysitters_using_natural_language_processing_and_computer_vision",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Cambridge Analytica Voter Targeting",
        "issue": "Influenced public through individualized ads and messaging that played a significant role in political campaigns in the US. Micro-targeting individuals in political campaigns.",
        "year": "2018",
        "domain": "Business",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.889248",
        "lng": "-77.050636",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/news/2018/mar/26/the-cambridge-analytica-files-the-story-so-far",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook Chatbot Shutdown",
        "issue": "Facebook shut down its virtual assistant chatbot experiment when it couldn't perform tasks",
        "year": "2019",
        "domain": "Business",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Helpful",
        "link": "https://www.bestpractice.ai/scenarios/automate_sales_conversations_through_a_text_chatbot",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Unmanned Catering Cart Broke",
        "issue": "Cart was not AI equipped but started conversations on how robots combat along",
        "year": "2019",
        "domain": "Business",
        "city": "Chicago",
        "state": "Illinois",
        "country": "USA",
        "lat": "41.883228",
        "lng": "-87.632401",
        "is_good": "Harmful",
        "link": "https://medium.com/syncedreview/2019-in-review-10-ai-failures-317b46155350",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Hotel Robot",
        "issue": "Henn-na Hotel used robots to replace human staff for certain roles. Robots were reported to fail at tasks and annoyed guests.",
        "year": "2019",
        "domain": "Business",
        "city": "",
        "state": "Tokyo",
        "country": "Japan",
        "lat": "35.689487",
        "lng": "139.691711",
        "is_good": "Harmful",
        "link": "https://www.bestpractice.ai/studies/henn_na_hotel_withdraws_robot_staff_as_they_annoy_guests_fail_to_provide_customer_service_and_break_down",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Amazon\u2019s Alexa started ordering people dollhouses after hearing its name on TV",
        "issue": "A TV advert created by Amazon triggered Alexa devices in the US to order a Dolls House on behalf of 100s of Customers. This purchase decision without a human input has a potential to be harmful.",
        "year": "2017",
        "domain": "Business",
        "city": "Washington DC",
        "state": "USA",
        "country": "",
        "lat": "38.907192",
        "lng": "-77.036873",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/2017/1/7/14200210/amazon-alexa-tech-news-anchor-order-dollhouse",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "What the Boston School Bus Schedule Can Teach Us About AI",
        "issue": "Ending schools at different time zones might prioritize various families differently",
        "year": "2018",
        "domain": "Education",
        "city": "Boston ",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.362293",
        "lng": "-71.059893",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/joi-ito-ai-and-bus-routes/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "IBM Watson Health Giving Dangerous Recommendations",
        "issue": "IBM's Watson for Oncology has been shown to have made dangerous errors in treatment recommendations and has been criticised for the system being trained on a small batch of doctors from one hospital in America.",
        "year": "2019",
        "domain": "Health Services",
        "city": "Armonk",
        "state": "New York",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Harmful",
        "link": "https://www.bestpractice.ai/studies/ibm_healths_watson_for_oncology_is_criticised_for_providing_inaccurate_and_potentially_dangerous_treatment_recommendations_for_cancer_patients",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "IBM Watson and MD Anderson Cancer Project",
        "issue": "IBM product did not work with Anderson's new electronic medical systems.",
        "year": "2017",
        "domain": "Health Services",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.267153",
        "lng": "-97.743057",
        "is_good": "Harmful",
        "link": "https://www.healthnewsreview.org/2017/02/md-anderson-cancer-centers-ibm-watson-project-fails-journalism-related/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "CES 2018: LG robot Cloi repeatedly fails on stage at its unveil",
        "issue": "Bot fails to demonstrate the enhance use of kitchen appliances",
        "year": "2018",
        "domain": "Housing",
        "city": "Yeouido-dong, Yeongdeungpo District",
        "state": "Seoul",
        "country": "South Korea",
        "lat": "37.566",
        "lng": "126.977",
        "is_good": "Harmful",
        "link": "https://www.bbc.com/news/technology-42614281",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Minority Neighborhoods Pay Higher Car Insurance",
        "issue": "Minority Neighborhoods Pay Higher Car Insurance Premiums Than White Areas With the Same Risk - ProPublica Study",
        "year": "2017",
        "domain": "Housing",
        "city": "Springfield",
        "state": "Illinois",
        "country": "USA",
        "lat": "39.79936",
        "lng": "-89.64362",
        "is_good": "Harmful",
        "link": "https://www.propublica.org/article/minority-neighborhoods-higher-car-insurance-premiums-white-areas-same-risk",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Minority Neighborhoods Pay Higher Car Insurance",
        "issue": "Minority Neighborhoods Pay Higher Car Insurance Premiums Than White Areas With the Same Risk - ProPublica Study",
        "year": "2017",
        "domain": "Housing",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.267153",
        "lng": "-97.73333",
        "is_good": "Harmful",
        "link": "https://www.propublica.org/article/minority-neighborhoods-higher-car-insurance-premiums-white-areas-same-risk",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Minority Neighborhoods Pay Higher Car Insurance",
        "issue": "Minority Neighborhoods Pay Higher Car Insurance Premiums Than White Areas With the Same Risk - ProPublica Study",
        "year": "2017",
        "domain": "Housing",
        "city": "Jackson",
        "state": "Missouri",
        "country": "USA",
        "lat": "37.380638",
        "lng": "-89.65062",
        "is_good": "Harmful",
        "link": "https://www.propublica.org/article/minority-neighborhoods-higher-car-insurance-premiums-white-areas-same-risk",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Twitter and Google Housing Ad Discrimination",
        "issue": "HUD is reviewing Twitter\u2019s and Google\u2019s ad practices as part of housing discrimination probe",
        "year": "2017",
        "domain": "Housing",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.7749",
        "lng": "-122.4194",
        "is_good": "Harmful",
        "link": "https://www.washingtonpost.com/business/2019/03/28/hud-charges-facebook-with-housing-discrimination/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "PredPol: Artificial Intelligence Is Now Used to Predict Crime. But Is It Biased?",
        "issue": "A Predictive policing software that started with LAPD and now is being used across the US. A lot of controversy on its use because of bias and feedback loops that reinforce targeting target minority neighborhoods",
        "year": "2018",
        "domain": "Law Enforcement",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.7749",
        "lng": "-122.4194",
        "is_good": "Harmful",
        "link": "https://www.smithsonianmag.com/innovation/artificial-intelligence-is-now-used-predict-crime-is-it-biased-180968337/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "COMPAS",
        "issue": "Case Management and Decision Support tool used in some U.S. courts to assess the likelihood of a defendant becoming a recidivist",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "New York   ",
        "state": "New York",
        "country": "USA",
        "lat": "40.7128",
        "lng": "-74.006",
        "is_good": "Harmful",
        "link": "https://www.natlawreview.com/article/would-you-trust-artificially-intelligent-expert",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "COMPAS",
        "issue": "Case Management and Decision Support tool used in some U.S. courts to assess the likelihood of a defendant becoming a recidivist",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Madison",
        "state": "Wisconsin",
        "country": "USA",
        "lat": "43.0731",
        "lng": "-89.4012",
        "is_good": "Harmful",
        "link": "https://www.natlawreview.com/article/would-you-trust-artificially-intelligent-expert",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "COMPAS",
        "issue": "Case Management and Decision Support tool used in some U.S. courts to assess the likelihood of a defendant becoming a recidivist",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Sacramento",
        "state": "California",
        "country": "USA",
        "lat": "38.5816",
        "lng": "-121.4944",
        "is_good": "Harmful",
        "link": "https://www.natlawreview.com/article/would-you-trust-artificially-intelligent-expert",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "COMPAS",
        "issue": "Case Management and Decision Support tool used in some U.S. courts to assess the likelihood of a defendant becoming a recidivist",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Broward County",
        "state": "Florida",
        "country": "USA",
        "lat": "26.1901",
        "lng": "-80.3659",
        "is_good": "Harmful",
        "link": "https://www.natlawreview.com/article/would-you-trust-artificially-intelligent-expert",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "South Wales Police Facial Recognition",
        "issue": "Facial recognition software to \"catch\" criminals in crowded settings had a 92% false-positive rate of identifying criminals.",
        "year": "2018",
        "domain": "Law Enforcement",
        "city": "",
        "state": "South Wales",
        "country": "UK",
        "lat": "51.47",
        "lng": "-3.705",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/uk-news/2018/may/05/welsh-police-wrongly-identify-thousands-as-potential-criminals",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Youtube Promotion of Conspiracy Videos",
        "issue": "Youtube's algorithms tend to promote videos with high engagement including conspiracy videos",
        "year": "2018",
        "domain": "Media",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/technology/2018/feb/02/how-youtubes-algorithm-distorts-truth",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Extremist Content on Social Media: New Zealand Prime Minister Jacinda Ardern wants Facebook, Google, and Twitter to help slow the spread of violent content online",
        "issue": "Christchurch terrorist attack was live streamed on Facebook",
        "year": "2019",
        "domain": "Media",
        "city": "",
        "state": "Christchurch",
        "country": "New Zealand",
        "lat": "-43.5321",
        "lng": "172.662",
        "is_good": "Harmful",
        "link": "https://www.businessinsider.com/new-zealand-talks-with-facebook-twitter-google-violent-content-online-2019-4",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "FACIAL RECOGNITION TECHNOLOGY FALSELY IDENTIFIES FAMOUS ATHLETES",
        "issue": "A facial-recognition system identified NFL champions as criminals",
        "year": "2019",
        "domain": "Society",
        "city": "Boston",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.362293",
        "lng": "-71.059893",
        "is_good": "Harmful",
        "link": "https://www.aclum.org/en/news/facial-recognition-technology-falsely-identifies-famous-athletes/#athletes",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "This Horrifying App Undresses a Photo of Any Woman With a Single Click",
        "issue": "Neural networks disrobe images of women",
        "year": "2019",
        "domain": "Society",
        "city": "Berkeley",
        "state": "California",
        "country": "USA",
        "lat": "37.8715",
        "lng": "-122.273",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en_us/article/kzm59x/deepnude-app-creates-fake-nudes-of-any-woman",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Airport and Payment Facial Recognition Systems Fooled by Masks and Photos, Raising Security Concerns",
        "issue": "Kneron was able to fool facial recognition system at banks, border crossing and airports using 3D masks",
        "year": "2019",
        "domain": "Society",
        "city": "San Diego",
        "state": "California ",
        "country": "USA",
        "lat": "32.716889",
        "lng": "-117.160398",
        "is_good": "Harmful",
        "link": "https://fortune.com/2019/12/12/airport-bank-facial-recognition-systems-fooled/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Microsoft's Tay Bot",
        "issue": "Tay bot project canceled after bot generated sexist and racist comments online",
        "year": "2016",
        "domain": "Society",
        "city": "Redmond",
        "state": "Washington    ",
        "country": "USA",
        "lat": "47.674",
        "lng": "-122.1215",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/2016/3/24/11297050/tay-microsoft-chatbot-racist",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Chinese Gov. Facial Recognition",
        "issue": "Government's facial recognition mistook woman's face on a bus ad for someone jaywalking.",
        "year": "2019",
        "domain": "Vision",
        "city": "Ningbo",
        "state": "Zhejiang",
        "country": "China",
        "lat": "29.87491",
        "lng": "121.537498",
        "is_good": "Harmful",
        "link": "https://www.bestpractice.ai/studies/chinese_facial_recognition_technology_mistakes_businesswomans_face_on_bus_ad_for_someone_actually_jaywalking",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Youtube Algorithm Incident",
        "issue": "Youtube Algorithm's potentially endangers kids",
        "year": "2017",
        "domain": "Society",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.npr.org/sections/thetwo-way/2017/11/27/566769570/youtube-faces-increased-criticism-that-its-unsafe-for-kids",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Cadillac Fairview Facial Recognition",
        "issue": "Suspends use of facial recognition software to track people's genders and ages",
        "year": "2018",
        "domain": "Vision",
        "city": "",
        "state": "Calgary",
        "country": "Canada",
        "lat": "51.048615",
        "lng": "-114.070847",
        "is_good": "Harmful",
        "link": "https://www.huffingtonpost.ca/2018/08/06/calgary-fairview-facial-recognition-software_a_23497017/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "US Dep. of Homeland Security Illegal Immigrant Lie Detector",
        "issue": "Questionability of ethical use in immigration, noticed flaws.",
        "year": "2017",
        "domain": "Society",
        "city": "",
        "state": "Mexico- United States Border",
        "country": "United States/Mexico",
        "lat": "33.33788",
        "lng": "-105.59617",
        "is_good": "Harmful",
        "link": "https://www.univision.com/univision-news/immigration/to-hire-5-000-new-border-agents-trump-administration-seeks-to-drop-lie-detector-test",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Apple Credit Card Discrimination",
        "issue": "Potential discrimination against women",
        "year": "2019",
        "domain": "Society",
        "city": "Cupertino",
        "state": "California ",
        "country": "USA",
        "lat": "37.323",
        "lng": "-122.0322",
        "is_good": "Harmful",
        "link": "https://edition.cnn.com/2019/11/12/business/apple-card-gender-bias/index.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook translates 'good morning' into 'attack them', leading to arrest",
        "issue": "Facebook translates good morning into attack them, leading into an arrest",
        "year": "2017",
        "domain": "Society",
        "city": "Palestine/Israel",
        "state": "",
        "country": "",
        "lat": "31.046051",
        "lng": "34.851612",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/technology/2017/oct/24/facebook-palestine-israel-translates-good-morning-attack-them-arrest",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Sexist Google Search",
        "issue": "Google Image Search for CEO has Barbie as first result",
        "year": "2015",
        "domain": "Society",
        "city": "By multiple accounts, the AFST has built-in human biases. One of the largest is that the system heavily weights past calls about families, such as from healthcare-providers, to the community hotline \u2014 and evidence suggests such calls are over three times more likely to involve Black and biracial families than white ones.",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.independent.co.uk/life-style/gadgets-and-tech/news/googles-algorithm-shows-prestigious-job-ads-to-men-but-not-to-women-10372166.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Minority Homebuyers Discrimination",
        "issue": "Minority homebuyers face widespread statistical lending discrimination,",
        "year": "2018",
        "domain": "Society",
        "city": "Berkeley",
        "state": "California",
        "country": "USA",
        "lat": "37.871593",
        "lng": "-122.272743",
        "is_good": "Harmful",
        "link": "https://news.berkeley.edu/story_jump/mortgage-algorithms-perpetuate-racial-bias-in-lending-study-finds/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Racial Discrimination in Targeted Housing Ads",
        "issue": "U.S. charges Facebook with racial discrimination in targeted housing ads",
        "year": "2019",
        "domain": "Society",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Harmful",
        "link": "https://www.reuters.com/article/us-facebook-advertisers/hud-charges-facebook-with-housing-discrimination-in-targeted-ads-on-its-platform-idUSKCN1R91E8",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "United Health Algorithm - Racial Bias",
        "issue": "UnitedHealth algorithm accused of racial bias gets scrutiny from N.Y. regulators - favors white patients over sicker black patients",
        "year": "2019",
        "domain": "Society",
        "city": "Minnetonka",
        "state": "Minnesota",
        "country": "USA",
        "lat": "44.934891",
        "lng": "-93.467056",
        "is_good": "Harmful",
        "link": "https://www.bizjournals.com/twincities/news/2019/10/29/unitedhealth-algorithm-accused-of-racial-bias-gets.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "One Month, 500,000 Face Scans: How China Is Using A.I. to Profile a Minority",
        "issue": "Facial recognition software used at checkpoints was rejecting people of Asian decent due to the shape of their eyes.",
        "year": "2019",
        "domain": "Society",
        "city": "",
        "state": "Beijing",
        "country": "China",
        "lat": "35.86166",
        "lng": "104.195396",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2019/04/14/technology/china-surveillance-artificial-intelligence-racial-profiling.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Alexa Without Human Input",
        "issue": "Police were called to a house in Germany that was expected of throwing a loud all-night party. Turned out Alexa had just decided to host her own rave and was playing music at 3am without her owner\u2019s knowledge.",
        "year": "2017",
        "domain": "Society",
        "city": "",
        "state": "Berlin ",
        "country": "Germany",
        "lat": "52.520008",
        "lng": "13.404954",
        "is_good": "Harmful",
        "link": "https://www.telegraph.co.uk/news/2017/11/08/alexa-nein-police-break-german-mans-house-music-device-held/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Clearview AI Facial Recognition",
        "issue": "Clearview AI uses any public image online to identify people",
        "year": "2020",
        "domain": "Vision",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.907192",
        "lng": "-77.036873",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2020/01/18/technology/clearview-privacy-facial-recognition.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Computer Vision",
        "issue": "7,500 unretouched nature photos confused SOTA computer vision models 98% of the time",
        "year": "2019",
        "domain": "Vision",
        "city": "Berkeley",
        "state": "California",
        "country": "USA",
        "lat": "37.8715",
        "lng": "-122.273",
        "is_good": "Harmful",
        "link": "https://medium.com/syncedreview/2019-in-review-10-ai-failures-317b46155350",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Predict IQ From Faces",
        "issue": "Faception released AI technology that could analyze facial images and bone structure to reveal person IQ and violent tendencies.",
        "year": "2020",
        "domain": "Vision",
        "city": "Jerusalem",
        "state": "Israel",
        "country": "Israel",
        "lat": "31.768318",
        "lng": "35.213711",
        "is_good": "Harmful",
        "link": "https://www.faception.com/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Computer Vision",
        "issue": "AI system recognized a photo of a Chinese billionaire as a jaywalker",
        "year": "2018",
        "domain": "Society",
        "city": "Ningbo",
        "state": "Zhejiang",
        "country": "China",
        "lat": "29.87491",
        "lng": "121.537498",
        "is_good": "Harmful",
        "link": "https://www.telegraph.co.uk/technology/2018/11/25/chinese-businesswoman-accused-jaywalking-ai-camera-spots-face/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Self-Driving Car",
        "issue": "Uber self-driving car kills a pedestrian",
        "year": "2018",
        "domain": "Vision",
        "city": "Tempe",
        "state": "Arizona",
        "country": "USA",
        "lat": "33.425522",
        "lng": "-111.941254",
        "is_good": "Harmful",
        "link": "https://www.ntsb.gov/investigations/AccidentReports/Reports/HWY18MH010-prelim.pdf",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Tesla Autopilot Crash Model X",
        "issue": "Defects on Model X cause crash",
        "year": "2019",
        "domain": "Society",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.bestpractice.ai/studies/drivers_demonstrate_teslas_autopilot_limitations_in_recreation_of_fatal_model_x_crash_at_exact_same_location",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Weaponized AI",
        "issue": "Weaponized AI can produce many unintended impacts from unwanted deaths to bombings",
        "year": "2018",
        "domain": "Law Enforcement",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.917192",
        "lng": "-77.066873",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/commentisfree/2018/oct/11/war-jedi-algorithmic-warfare-us-military",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Project Maven",
        "issue": "Palantir and government project used to track, tag and spy on targets without human involvement",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Palo Alto",
        "state": "California",
        "country": "USA",
        "lat": "37.441883",
        "lng": "-122.143021",
        "is_good": "Harmful",
        "link": "https://thenextweb.com/artificial-intelligence/2019/12/11/report-palantir-took-over-project-maven-the-military-ai-program-too-unethical-for-google/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Lethal Autonomous Weapons",
        "issue": "There has been consideration for lethal autonomous weapons",
        "year": "2019",
        "domain": "Law Enforcement",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.897675",
        "lng": "-77.04653",
        "is_good": "Harmful",
        "link": "https://www.vox.com/2019/6/21/18691459/killer-robots-lethal-autonomous-weapons-ai-war",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Unmanned Aerial Weapon System",
        "issue": "US Army is developing an automatic target recognition unmanned aerial system",
        "year": "2018",
        "domain": "Law Enforcement",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.897675",
        "lng": "-77.02653",
        "is_good": "Harmful",
        "link": "https://www.sbir.gov/sbirsearch/detail/1413823",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Arms Race Dynamic with AI Weapons",
        "issue": "China fears that AI arms race can cause war",
        "year": "2019",
        "domain": "Law Enforcement",
        "city": "",
        "state": "Beijing",
        "country": "China",
        "lat": "35.86166",
        "lng": "104.195396",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/2019/2/6/18213476/china-us-ai-arms-race-artificial-intelligence-automated-warfare-military-conflict",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "University Admissions decided by AI",
        "issue": "Salesforce has a contract with a few schools to predict student outcomes and make decisions on financial aid.",
        "year": "2019",
        "domain": "Education",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.7749",
        "lng": "-122.4194",
        "is_good": "Harmful",
        "link": "https://www.fastcompany.com/90342596/schools-are-quietly-turning-to-ai-to-help-pick-who-gets-in-what-could-go-wrong",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Focus of AI in Education",
        "issue": "The main focus when thinking about AI is looking at how to automate school, not thinking about what is useful for students",
        "year": "2019",
        "domain": "Education",
        "city": "",
        "state": "",
        "country": "",
        "lat": "",
        "lng": "",
        "is_good": "Harmful",
        "link": "https://www.jisc.ac.uk/news/the-ai-revolution-is-here-17-aug-2018",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facial Recognition to Measure Attention",
        "issue": "A school utilizes AI to track students' attention in class",
        "year": "2018",
        "domain": "Education",
        "city": "",
        "state": "Hangzhou",
        "country": "China",
        "lat": "30.274084",
        "lng": "120.155067",
        "is_good": "Harmful",
        "link": "https://www.telegraph.co.uk/news/2018/05/17/chinese-school-uses-facial-recognition-monitor-student-attention",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Using AI to Track Student's Feelings",
        "issue": "Algebra Nation's software tracks students down to keystrokes and long in",
        "year": "2018",
        "domain": "Education",
        "city": "",
        "state": "Florida",
        "country": "USA",
        "lat": "27.664827",
        "lng": "-81.515755",
        "is_good": "Harmful",
        "link": "https://mobile.edweek.org/c.jsp?cid=25919761&bcid=25919761&rssid=25919751&item=http%3A%2F%2Fapi.edweek.org%2Fv1%2Few%2Findex.html%3Fuuid%3DC08929D8-6E6F-11E8-BE8B-7F0EB4743667",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Lack of Transparency in Available Health Data",
        "issue": "McKinsey states that there is slow adoption on health insurers utilizing AI because of a lack of transparency regarding available data",
        "year": "2017",
        "domain": "Health Services",
        "city": "",
        "state": "",
        "country": "Germany",
        "lat": "51.165691",
        "lng": "10.451526",
        "is_good": "Harmful",
        "link": "https://healthcare.mckinsey.com/sites/default/files/Artificial%20intelligence%20in%20Health%20Insurance.pdf",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI in Hospital Wait Times",
        "issue": "AI can mirror decisions made by human physicians without a human deciding them",
        "year": "2019",
        "domain": "Health Services",
        "city": "",
        "state": "Edinburgh",
        "country": "Scotland",
        "lat": "55.9533",
        "lng": "3.1883",
        "is_good": "Harmful",
        "link": "https://apolitical.co/en/solution_article/hospitals-are-using-ai-to-slash-wait-times-and-solve-doctor-shortages",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Software Prescribing Health Software ",
        "issue": "The growing field of digital therapeutics does not include a human in the loop and has room for error",
        "year": "2019",
        "domain": "Health Services",
        "city": "",
        "state": "London",
        "country": "England",
        "lat": "51.007351",
        "lng": "-0.187758",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2019/03/21/science/health-medicine-artificial-intelligence.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Prospect of Adversarial Attacks",
        "issue": "One can trick an AI system by manipulating pixels to thinking there is a disease",
        "year": "2019",
        "domain": "Health Services",
        "city": "Boston ",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.360081",
        "lng": "-71.058884",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2019/03/21/science/health-medicine-artificial-intelligence.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI Systems Deployed in Different Hospitals Fail",
        "issue": "Mildred Cho found that software developed in one hospital often fails at other hospitals. It also discriminates against minorities and is dependent on factors such as the brand of MRI machines",
        "year": "2019",
        "domain": "Health Services",
        "city": "Palo Alto",
        "state": "California",
        "country": "USA",
        "lat": "37.441883",
        "lng": "-122.143021",
        "is_good": "Harmful",
        "link": "https://khn.org/news/a-reality-check-on-artificial-intelligence-are-health-care-claims-overblown/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Health AI App Produces Wrong Predictions",
        "issue": "The application was unable to take contextual information and had doctors send patients home when they should not have",
        "year": "2018",
        "domain": "Health Services",
        "city": "",
        "state": "London",
        "country": "England",
        "lat": "51.907351",
        "lng": "-0.07758",
        "is_good": "Harmful",
        "link": "https://www.nuffieldbioethics.org/assets/pdfs/Artificial-Intelligence-AI-in-healthcare-and-research.pdf",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Mishandling NHS Data",
        "issue": "The Royal Free London NHS Foundation Trust mishandled 1.6 million patient's data after they supplied it to DeepMind without their consent",
        "year": "2017",
        "domain": "Health Services",
        "city": "",
        "state": "London",
        "country": "England",
        "lat": "51.507351",
        "lng": "-0.127758",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/technology/2017/jul/03/google-deepmind-16m-patient-royal-free-deal-data-protection-act",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Gender Stereotypes in Chatbot",
        "issue": "Gender prejudices found in bots start impacting women such as boys barking orders at girls named Alexa and engaging in dirty conversations with bots",
        "year": "2020",
        "domain": "Society",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.7749",
        "lng": "-122.4194",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/careers/2020/may/07/ai-and-me-friendship-chatbots-are-on-the-rise-but-is-there-a-gendered-design-flaw",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Bias in Pothole Detection",
        "issue": "The recording of potholes was skewed as it disadvantaged populations without smartphones",
        "year": "2019",
        "domain": "Society",
        "city": "Boston",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.360081",
        "lng": "-71.058884",
        "is_good": "Harmful",
        "link": "https://thesumisgreater.wordpress.com/2016/09/21/big-data-has-unconscious-bias-too/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Police Facial Recognition Could Misidentify People after Protests",
        "issue": "The AI being used to detect protestors of racial injustice is trained primarily on white males which means that the probability of misdetection people of color is higher. ",
        "year": "2020",
        "domain": "Society",
        "city": "Minneapolis",
        "state": "Minnesota",
        "country": "USA",
        "lat": "44.986656",
        "lng": "-93.258133",
        "is_good": "Harmful",
        "link": "https://www.digitaltrends.com/news/police-protests-facial-recognition-misidentification/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Unequal Access to Amazon's Prime Free Same-Day Delivery",
        "issue": "At first, Amazon did not consider race in their predictive algorithm for areas that should have same-day Prime Delivery. The algorithm still picked up on race and discriminated against minority urban neighborhoods. Since this article has been released, Amazon has extended same day delivery after political figures urged them in Boston, Chicago and New York.",
        "year": "2016",
        "domain": "Society",
        "city": "Seattle",
        "state": "Washington",
        "country": "USA",
        "lat": "47.606209",
        "lng": "-122.332069",
        "is_good": "Harmful",
        "link": "https://www.bloomberg.com/graphics/2016-amazon-same-day/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Hospital Risk Scores Prioritize White Patients",
        "issue": "The risks scores assigned to patients by hospitals and insurance companies was giving low risk scores to Black patients because the algorithm was using bills and insurance payouts as a proxy for human health. However, health care costs tend to be lower for Black patients because they tend to live farther away from hospitals or less flexible job schedules. Therefore, Black Patients who had the highest risk scores actually had more severe chronic condition. By tweaking the algorithm to predict amount of illness rather than cost, racial disparity was reduced by 84%.",
        "year": "2019",
        "domain": "Society",
        "city": "Berkeley",
        "state": "California",
        "country": "USA",
        "lat": "37.871666",
        "lng": "-122.272781",
        "is_good": "Harmful",
        "link": "https://www.sciencemag.org/news/2019/10/hospital-risk-scores-prioritize-white-patients",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Microsoft Bot Confuses Little Mix Singers",
        "issue": "The Guardian planned on firing human editors and implementing Microsoft's Bot which later selected the wrong Little Mix singer for an article.",
        "year": "2020",
        "domain": "Society",
        "city": "Seattle",
        "state": "Washington",
        "country": "USA",
        "lat": "47.606209",
        "lng": "-122.332069",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/technology/2020/jun/09/microsofts-robot-journalist-confused-by-mixed-race-little-mix-singers",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Data Privacy Concerns with Tim Hortons",
        "issue": "Tim Hortons records longitude and latitude of app users outside of the app",
        "year": "2020",
        "domain": "Business ",
        "city": "",
        "state": "Oakville",
        "country": "Canada",
        "lat": "43.466789",
        "lng": "-79.6903",
        "is_good": "Harmful",
        "link": "https://business.financialpost.com/technology/tim-hortons-app-tracking-customers-intimate-data",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Criminal Risk Assessment Algorithms are Biased",
        "issue": "The risk assessment tools are based on historical crime data that show correlations rather than causations which turn correlative insights into causal scoring mechanisms.",
        "year": "2019",
        "domain": "Law Enforcement",
        "city": "Boston ",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.00081",
        "lng": "-71",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2019/01/21/137783/algorithms-criminal-justice-ai/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Contact Tracing Apps Must Consider Data Privacy",
        "issue": "The rise of contract tracing apps raises data privacy concerns and legal ramifications.",
        "year": "2020",
        "domain": "Health Services",
        "city": "Cambridge",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.373611",
        "lng": "-71.110558",
        "is_good": "Harmful",
        "link": "https://www.informationweek.com/strategic-cio/security-and-risk-strategy/why-ai-ethics-is-even-more-important-now/a/d-id/1337922",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Cardiology AI Protocol: Life and Death Ramifications Exist",
        "issue": "Ramifications exist if a cardiology AI protocol does not have the right inputs and parameters.",
        "year": "2020",
        "domain": "Health Services",
        "city": "Armonk ",
        "state": "New York",
        "country": "USA",
        "lat": "41.1264849",
        "lng": "-73.7140195",
        "is_good": "Harmful",
        "link": "https://www.forbes.com/sites/jeffgorke/2020/06/18/ai-and-machine-learning-in-healthcare-garbage-in-garbage-out/#1bce77cc50a7",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI Can Amplify Medical Mistakes",
        "issue": "AI can amplify medical mistakes by overtesting, overdiagnosis, and overtreatment",
        "year": "2020",
        "domain": "Health Services",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/artificial-intelligence-makes-bad-medicine-even-worse/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A Black Man is Wrongly Accused of a Crime by an Algorithm ",
        "issue": "Mr.Williams is wrongly accused of shoplifting after an algorithm suggested his face matched the phone in the photo.",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Detroit",
        "state": "Michigan",
        "country": "USA",
        "lat": "42.331429",
        "lng": "-83.045753",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2020/06/24/technology/facial-recognition-arrest.html , https://www.reuters.com/article/us-michigan-facial-recognition/face-recognition-vendor-vows-new-rules-after-wrongful-arrest-in-us-using-its-technology-idUSKBN23V1KJ , https://www.washingtonpost.com/opinions/2020/06/24/i-was-wrongfully-arrested-because-facial-recognition-why-are-police-allowed-use-this-technology/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google's AI Hate Speech Detector is Racially Biased",
        "issue": "This tool profiled tweets by African-Americans as hate speech.",
        "year": "2019",
        "domain": "Law Enforcement",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.forbes.com/sites/nicolemartin1/2019/08/13/googles-artificial-intelligence-hate-speech-detector-is-racially-biased/#2fd08795326c",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "YOLO Creator Quits AI Research because of AI Ethical Concerns",
        "issue": "Due to the possible military applications of YOLO computer vision algorithm, Joe Redmon stops his research.",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Seattle ",
        "state": "Washington",
        "country": "USA",
        "lat": "47.006209",
        "lng": "-122.002069",
        "is_good": "Harmful",
        "link": "https://analyticsindiamag.com/yolo-creator-joe-redmon-computer-vision-research-ethical-concern/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "IBM Watson team had to scrape the Urban Dictionary from Watson memory",
        "issue": "IBM Watson is unable to distinguish between polite and rude words.",
        "year": "2013",
        "domain": "Society",
        "city": "Armonk",
        "state": "New York",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Harmful",
        "link": "https://www.theatlantic.com/technology/archive/2013/01/ibms-watson-memorized-the-entire-urban-dictionary-then-his-overlords-had-to-delete-it/267047/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Formula predicts what student's scores would have been",
        "issue": "The International Baccalaureate program used a math model to predict test scores for students which has caused students to lose scholarships",
        "year": "2020",
        "domain": "Education",
        "city": "",
        "state": "Grand-Saconnex",
        "country": "Switzerland",
        "lat": "46.23623",
        "lng": "6.12633",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/algorithm-set-students-grades-altered-futures/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Man falsely accused of larceny due to facial recognition software",
        "issue": "The facial recognition software used by the Detroit Police Department misclassified a man and charged him with larceny.",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Detroit",
        "state": "Michigan",
        "country": "USA",
        "lat": "42.931429",
        "lng": "-83.945753",
        "is_good": "Harmful",
        "link": "https://www.freep.com/story/news/local/michigan/detroit/2020/07/10/facial-recognition-detroit-michael-oliver-robert-williams/5392166002/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google Search of Black Girls results in Pornography",
        "issue": "Google's Search engine shows pornography when a use enters black girls.Although this specific error has been fixed, Latina and Asian girls are still sexualized in results. Furthermore, a search for professional and unprofessional haircuts shows racial bias as well.",
        "year": "2018",
        "domain": "Society",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2018/02/26/3299/meet-the-woman-who-searches-out-search-engines-bias-against-women-and-minorities/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Black Women have to wear a white mask to be recognized by AI",
        "issue": "Joy Buolamwini had to wear a white mask for a robot to recognize her face. In fact, 50% of faces are not recognized by facial recognition software.",
        "year": "2017",
        "domain": "Vision",
        "city": "",
        "state": "",
        "country": "Hong Kong",
        "lat": "22.302711",
        "lng": "114.177216.",
        "is_good": "Harmful",
        "link": "https://www.poetofcode.com, https://www.theguardian.com/technology/2017/may/28/joy-buolamwini-when-algorithms-are-racist-facial-recognition-bias",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Women are less likely to be shown ads for high-paid jobs on Google",
        "issue": "A Carnegie Mellon team build AdFisher ( a system of male and female job seekers) and found males were shown ads to seek high paying jobs more than females.",
        "year": "2015",
        "domain": "Society",
        "city": "Pittsburgh",
        "state": "Pennsylvania",
        "country": "USA",
        "lat": "40.4006",
        "lng": "-80.102951",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/technology/2015/jul/08/women-less-likely-ads-high-paid-jobs-google-study",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI systems from top companies such as Microsoft, IBM Watson, Amazon, and Google label women as men or not faces.",
        "issue": "AI, Ain't I a Woman by Joy Buolamwini reveals that top AI systems mislabel Black Women.",
        "year": "2018",
        "domain": "Vision",
        "city": "Cambridge",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.360001",
        "lng": "-71.092003",
        "is_good": "Harmful",
        "link": "https://www.youtube.com/watch?time_continue=108&v=QxuyfWoVV98&feature=emb_logo",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Research shows that AI is likely to pick the unethical strategy",
        "issue": "Researchers show that AI has an unethical optimization principle",
        "year": "2020",
        "domain": "Society",
        "city": "Lausanne",
        "state": "",
        "country": "Switzerland",
        "lat": "46.51963",
        "lng": "6.63213",
        "is_good": "Harmful",
        "link": "https://www.eurekalert.org/pub_releases/2020-06/epfd-eaa063020.php",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "UK Policies uses AI that amplifies human bias",
        "issue": "Predictive Policing tools in UK use datasets with human bias. Furthermore, individuals from a disadvantaged socio demographic background are likely to engage more with public services which leads to more data and calculates them as posing a greater risk.",
        "year": "2019",
        "domain": "Law Enforcement",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "51.507351",
        "lng": "-0.127758",
        "is_good": "Harmful",
        "link": "https://www.mirror.co.uk/tech/artificial-intelligence-used-uk-police-20078716",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Uber Algorithms are breaking EU Data protection laws",
        "issue": "Uber drivers take Uber to court for not sharing data and potentially unfairly profiling drivers",
        "year": "2020",
        "domain": "Society",
        "city": "Amsterdam",
        "state": "",
        "country": "Netherlands",
        "lat": "52.377956",
        "lng": "4.89707",
        "is_good": "Harmful",
        "link": "https://gizmodo.com/ubers-algorithms-are-being-taken-to-court-1844442414",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Palantir puts UN's highly sensitive data about food aid recipients at risk",
        "issue": "UN shares highly sensitive data with Palantir which adds risk of harm and exploitation",
        "year": "2019",
        "domain": "Society",
        "city": "Geneva",
        "state": "",
        "country": "Switzerland",
        "lat": "46.204391",
        "lng": "6.143158",
        "is_good": "Harmful",
        "link": "https://www.thenewhumanitarian.org/news/2019/02/05/un-palantir-deal-data-mining-protection-concerns-wfp",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Gaydar enables governments and organizations to target LGBT",
        "issue": "Researchers at Stanford University trained a facial recognition system that could tell if someone was gay or straight from photos. ",
        "year": "2017",
        "domain": "Vision",
        "city": "Palo Alto",
        "state": "California",
        "country": "USA",
        "lat": "37.424107",
        "lng": "-122.166077",
        "is_good": "Harmful",
        "link": "https://www.bloomberg.com/opinion/articles/2017-09-25/-gaydar-shows-how-creepy-algorithms-can-get",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "US asylum seekers must submit biometric check ins",
        "issue": "SmartLink shares GPS active data of immigrants",
        "year": "2020",
        "domain": "Society",
        "city": "Boca Raton",
        "state": "Florida",
        "country": "USA",
        "lat": "26.368305",
        "lng": "-80.128929",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/world/2020/jul/24/surveillance-tech-facial-recognition-terror-capitalism",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facial Recognition used to monitor Palestinians in West Bank",
        "issue": "AnyVision, a facial recognition system, monitors Palestinians in the West Bank",
        "year": "2020",
        "domain": "Society",
        "city": "West Bank",
        "state": "",
        "country": "Israel",
        "lat": "31.946569",
        "lng": "35.302723",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/world/2020/jul/24/surveillance-tech-facial-recognition-terror-capitalism",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facial Recognition and Data Scraping on Turkic Muslims",
        "issue": "China uses facial recognition software to track movements and scraps data from Turkic Muslims in \"re-education\" camps",
        "year": "2020",
        "domain": "Vision",
        "city": "Xinjiang",
        "state": "",
        "country": "China",
        "lat": "39.93084",
        "lng": "116.38634",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/world/2020/jul/24/surveillance-tech-facial-recognition-terror-capitalism",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Tinder Dating Apps data sharing policies can harm LGTQ+",
        "issue": "Russia demands Tinder to give user data to identify LBGTQ+",
        "year": "2019",
        "domain": "Society",
        "city": "Chechnya",
        "state": "",
        "country": "Russia",
        "lat": "43.30592",
        "lng": "45.74697",
        "is_good": "Harmful",
        "link": "https://www.usatoday.com/story/opinion/2019/06/14/tinder-data-sharing-russia-endangers-lgbtq-community-column/1350929001/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Police in Egypt uses technology to identify homosexuals",
        "issue": "Police utilize entrapment techniques on dating apps and video footage from concerts",
        "year": "2017",
        "domain": "Society",
        "city": "Cairo",
        "state": "",
        "country": "Egypt",
        "lat": "30.033333",
        "lng": "31.233334",
        "is_good": "Harmful",
        "link": "https://www.hrw.org/news/2017/09/30/egypt-stop-anti-lgbt-crackdown-intimidation#",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Saudi Arabia uses social media to target homosexuals",
        "issue": "Man arrested for using Facebook to find other men to date",
        "year": "2012",
        "domain": "Society",
        "city": "Riyadh",
        "state": "",
        "country": "Saudi Arabia",
        "lat": "24.774265",
        "lng": "46.738586",
        "is_good": "Harmful",
        "link": "https://www.pinknews.co.uk/2012/01/13/man-arrested-for-facebook-gay-date-in-saudi-arabia/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Massive Internet Entrapment Campaign puts dozens of homosexual men in jail",
        "issue": "Iran utilizes various tools from messages in private chat rooms to target gays",
        "year": "2006",
        "domain": "Society",
        "city": "Mashad",
        "state": "",
        "country": "Iran",
        "lat": "36.310699",
        "lng": "59.599457",
        "is_good": "Harmful",
        "link": "http://inthesetimes.com/article/2458/iran_anti_gay_pogrom",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "LGBTQ+ apps have lead to arrests and intimidations ",
        "issue": "The discovery of LGBTQ+ apps on mobile phones have led to arrests and intimidation especially towards LGBTQ+ Syrian refugees",
        "year": "2018",
        "domain": "Society",
        "city": "Beirut",
        "state": "",
        "country": "Lebanon",
        "lat": "33.88863",
        "lng": "35.49548",
        "is_good": "Harmful",
        "link": "https://www.article19.org/wp-content/uploads/2018/02/LGBTQ-Apps-Arrest-and-Abuse-report_22.2.18.pdf",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "By changing a few pixels on a lung scan, someone could fool an A.I. system into seeing an illness that is not really there, or not seeing one that is",
        "issue": "In a paper published in the Journal Science, the researchers raise the prospect of \u201cadversarial attacks\u201d \u2014 manipulations that can change the behavior of A.I. systems using tiny pieces of digital data. By changing a few pixels on a lung scan, for instance, someone could fool an A.I. system into seeing an illness that is not really there, or not seeing one that is.",
        "year": "2019",
        "domain": "Health Services",
        "city": "Boston",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.361145",
        "lng": "-71.057083",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2019/03/21/science/health-medicine-artificial-intelligence.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Rite Aid deployed secret facial recognition systems in largely lower-income, non-white neighborhoods for over a decade",
        "issue": "Rite Aid continues to use facial recognition software primarily in low-income and non-white neighborhoods even after their announcement of quitting use of facial recognition software.",
        "year": "2020",
        "domain": "Vision",
        "city": "Camp Hill",
        "state": "Pennsylvania",
        "country": "USA",
        "lat": "40.23981",
        "lng": "-76.91997",
        "is_good": "Harmful",
        "link": "https://www.reuters.com/investigates/special-report/usa-riteaid-software/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Police uses facial recognition and social media to arrest protesters ",
        "issue": "Richland County Police uses facial recognition and social media to arrest protestors.",
        "year": "2020",
        "domain": "Vision",
        "city": "Columbia",
        "state": "South Carolina",
        "country": "USA",
        "lat": "34.00071",
        "lng": "-81.034813",
        "is_good": "Harmful",
        "link": "https://www.thestate.com/news/local/crime/article244433082.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook settles where it claims that it illegally collected and stored biometric data for millions of users without consent",
        "issue": "Wrong",
        "year": "2020",
        "domain": "Society",
        "city": "Springfield",
        "state": "Illinois",
        "country": "USA",
        "lat": "39.99936",
        "lng": "-89.04362",
        "is_good": "Harmful",
        "link": "https://www.reuters.com/article/us-facebook-privacy-lawsuit/facebook-raises-settlement-to-650-million-in-facial-recognition-lawsuit-idUSKCN24W313",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "MIT removed a 80 Million Tiny Images, a dataset that was popular with AI developers",
        "issue": "AI described women with derogatory terms, body parts were identified with offensive slang, and racial slurs were used to label minority people.",
        "year": "2020",
        "domain": "Society ",
        "city": "Cambridge",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.060001",
        "lng": "-71.002003",
        "is_good": "Harmful",
        "link": "https://www.bbntimes.com/technology/artificial-intelligence-is-evil-with-unethical-data",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Lawsuits allege Microsoft, Amazon, and Google violated Illinois facial recognition privacy law",
        "issue": "Biometric data was harvested or stored without explicit permission",
        "year": "2020",
        "domain": "Vision",
        "city": "Springfield",
        "state": "Illinois",
        "country": "USA",
        "lat": "39.79936",
        "lng": "-89.34362",
        "is_good": "Harmful",
        "link": "https://techcrunch.com/2020/07/15/facial-recognition-lawsuit-vance-janecyk-bipa/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Banking Risks with AI",
        "issue": "Banks must work towards resolving risks with data bias, \"black box\" risk and a lack of human oversight",
        "year": "2020",
        "domain": "Business",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "51.839236",
        "lng": "-0.13349",
        "is_good": "Harmful",
        "link": "https://www.finextra.com/pressarticle/83354/banks-must-resolve-explainability-and-black-box-risk-governance-challenges-to-succeed-with-ai-post",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Coloniality Manifests in AI",
        "issue": "Coloniality manifests in AI through algorithmic discrimination and oppression, ghost work, beta testing, AI governance, and international social development.",
        "year": "2020",
        "domain": "Society",
        "city": "Cambridge",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.000001",
        "lng": "-71",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2020/07/31/1005824/decolonial-ai-for-everyone/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Algorithms disrupted 2017 Kenyan election",
        "issue": "Beta Testing algorithms in 2017 Kenyan election disrupted the Kenyan election process.",
        "year": "2019",
        "domain": "Society",
        "city": "Nairobi",
        "state": "",
        "country": "Kenya",
        "lat": "-1.286389",
        "lng": "36.817223",
        "is_good": "Harmful",
        "link": "https://www.foreignaffairs.com/reviews/capsule-review/2019-08-12/digital-democracy-analogue-politics-how-internet-era-transforming",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Twitter won't remove deepfakes",
        "issue": "Twitter will warn users about deepfakes, but won't remove it",
        "year": "2019",
        "domain": "Society",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.7765",
        "lng": "-122.4172",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2019/11/12/217/twitter-says-it-may-warn-users-about-deepfakesbut-wont-remove-them/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "The Pentagon can identify people by their heartbeat",
        "issue": "From a distance, the Pentagon can identify people by their cardiac signature",
        "year": "2019",
        "domain": "Law Enforcement",
        "city": "Arlington",
        "state": "Virginia",
        "country": "USA",
        "lat": "38.871857",
        "lng": "-77.056267",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2019/06/27/238884/the-pentagon-has-a-laser-that-can-identify-people-from-a-distanceby-their-heartbeat/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "College Student is misidentified as Easter Sunday terrorist",
        "issue": "Sri Lankan police used photo of Brown University student as wanted suspect in terror attack",
        "year": "2019",
        "domain": "Law Enforcement",
        "city": "Colombo",
        "state": "",
        "country": "Sri Lanka",
        "lat": "6.927079",
        "lng": "79.861244",
        "is_good": "Harmful",
        "link": "https://www.washingtonpost.com/nation/2019/04/26/sri-lankan-police-wrongly-identify-brown-university-student-wanted-suspect-terror-attack/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Face Reconstruction from Voice using Generative Adversarial Networks",
        "issue": "Researchers have found a way to picture a face given an audio clip",
        "year": "2019",
        "domain": "Vision",
        "city": "Pittsburgh",
        "state": "Pennsylvania",
        "country": "USA",
        "lat": "40.4006",
        "lng": "-80.102951",
        "is_good": "Harmful",
        "link": "https://papers.nips.cc/paper/8768-face-reconstruction-from-voice-using-generative-adversarial-networks",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI technology can identify genetic diseases by looking at your face, study says",
        "issue": "Deep Gestalt can potentially analyze facial images and discriminate with pre-existing conditions",
        "year": "2020",
        "domain": "Health Services",
        "city": "Boston",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.360081",
        "lng": "-71.058884",
        "is_good": "Harmful",
        "link": "https://edition.cnn.com/2019/01/08/health/ai-technology-to-identify-genetic-disorder-from-facial-image-intl/index.html?no-st=1549146304",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Genderify assumes someone's gender based on their name",
        "issue": "Genderify suggests gender to someone's name and has errors with Dr.",
        "year": "2019",
        "domain": "Society",
        "city": "Glendale",
        "state": "California",
        "country": "USA",
        "lat": "34.142509",
        "lng": "-118.255074",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/2020/7/29/21346310/ai-service-gender-verification-identification-genderify",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Automated Inference on Criminality Using Face Images",
        "issue": "A neural network that analyzes facial features and classifies whether a person is a criminal or not",
        "year": "2017",
        "domain": "Vision",
        "city": "Shanghai",
        "state": "",
        "country": "China",
        "lat": "31.025486",
        "lng": "121.43",
        "is_good": "Harmful",
        "link": "https://arxiv.org/abs/1611.04135",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "US military studied how to influence Twitter users in Darpa-funded research",
        "issue": "The Defense department has analyzed memes, celebrities and disinformation",
        "year": "2014",
        "domain": "Media",
        "city": "Arlington County",
        "state": "Virginia",
        "country": "USA",
        "lat": "41.18766",
        "lng": "-80.947319",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/world/2014/jul/08/darpa-social-networks-research-twitter-influence-studies",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "First Evidence That Social Bots Play a Major Role in Spreading Fake News",
        "issue": "Over 14 million Twitter posts mention fake news claims",
        "year": "2017",
        "domain": "Media",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.774929",
        "lng": "-122.419418",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2017/08/07/150097/first-evidence-that-social-bots-play-a-major-role-in-spreading-fake-news/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A virtual policeman assess whether someone is lying",
        "issue": "iBorderCtrl is being pursued despite a lack of transparency and racial bias",
        "year": "2019",
        "domain": "Law Enforcement",
        "city": "Manchester",
        "state": "",
        "country": "England",
        "lat": "53.480759",
        "lng": "-2.242631",
        "is_good": "Harmful",
        "link": "https://theintercept.com/2019/07/26/europe-border-control-ai-lie-detector/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facial Recognition classifies pedophiles and terrorists",
        "issue": "A facial recognition system uses facial features to classify whether someone is a terrorist, pedophile etc.",
        "year": "2016",
        "domain": "Vision",
        "city": "Tel Aviv",
        "state": "",
        "country": "Israel",
        "lat": "32.05305",
        "lng": "34.77236",
        "is_good": "Harmful",
        "link": "https://www.computerworld.com/article/3075339/faception-can-allegedly-tell-if-youre-a-terrorist-just-by-analyzing-your-face.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Pentagon funds research to predict civil unrest",
        "issue": "Pentagon funds social media surveillance to predict anti-Trump protests",
        "year": "2018",
        "domain": "Law Enforcement",
        "city": "Arlington",
        "state": "Virginia",
        "country": "USA",
        "lat": "38.071857",
        "lng": "-77.006267",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en_us/article/7x3g4x/pentagon-wants-to-predict-anti-trump-protests-using-social-media-surveillance",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Gait Analysis",
        "issue": "A way to identify individuals based on gait pattern from video recordings",
        "year": "2017",
        "domain": "Vision",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "51.50609",
        "lng": "-0.13262",
        "is_good": "Harmful",
        "link": "https://royalsociety.org/~/media/about-us/programmes/science-and-law/royal-society-forensic-gait-analysis-primer-for-courts.pdf",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Chinese Government Facial Recognition ",
        "issue": "Sensetime enables cameras to be enhanced with machine learning to find faces",
        "year": "2018",
        "domain": "Vision",
        "city": "Hong Kong",
        "state": "",
        "country": "",
        "lat": "22.41855",
        "lng": "114.209",
        "is_good": "Harmful",
        "link": "https://www.forbes.com/sites/shuchingjeanchen/2018/03/07/the-faces-behind-chinas-omniscient-video-surveillance-technology/#1415a3dd4afc",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Automatic Censorship on Messaging App",
        "issue": "WeChat automatically censors images critical of the Chinese Communist Party",
        "year": "2019",
        "domain": "Society",
        "city": "Shenzhen",
        "state": "",
        "country": "China",
        "lat": "22.542883",
        "lng": "114.062996",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2019/07/15/134178/how-wechat-censors-private-conversations-automatically-in-real-time/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Social Credit Surveillance",
        "issue": "Sesame credit scores people from 350 to 950 based on interpersonal relationships and consumer habits",
        "year": "2018",
        "domain": "Society",
        "city": "Hangzhou",
        "state": "",
        "country": "China",
        "lat": "30.274084",
        "lng": "120.155067",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/commentisfree/2018/mar/05/algorithms-rate-credit-scores-finances-data",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Zach, Medical AI is a scam",
        "issue": "An AI that took notes from health patients",
        "year": "2018",
        "domain": "Health Services",
        "city": "Christchurch",
        "state": "",
        "country": "New Zealand",
        "lat": "-43.032055",
        "lng": "172.03623",
        "is_good": "Harmful",
        "link": "https://thespinoff.co.nz/the-best-of/06-03-2018/the-mystery-of-zach-new-zealands-all-too-miraculous-medical-ai/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "TikTok creates filter bubbles based on race",
        "issue": "Due to a biased feedback loop, Tik Tok uses race, age and gender for profile recommendations",
        "year": "2020",
        "domain": "Media",
        "city": "Beijing",
        "state": "",
        "country": "China",
        "lat": "39.004202",
        "lng": "116.207394",
        "is_good": "Harmful",
        "link": "https://www.wired.co.uk/article/tiktok-filter-bubbles",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook algorithm found to 'actively promote' Holocaust denial",
        "issue": "When users typed holocaust, the Facebook search function brought up suggestions for denial pages",
        "year": "2020",
        "domain": "Media",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/world/2020/aug/16/facebook-algorithm-found-to-actively-promote-holocaust-denial",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Chinese voice bots",
        "issue": "An increase of fraud and privacy as chinese voice bots are the new telemarketers",
        "year": "2020",
        "domain": "Business ",
        "city": "Shanghai",
        "state": "",
        "country": "China",
        "lat": "31.230391",
        "lng": "121.473701",
        "is_good": "Harmful",
        "link": "https://www.scmp.com/week-asia/economics/article/3096539/think-telemarketers-are-pest-wait-till-chinas-ai-versions-call",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "40% of A-level results predictions are downgraded",
        "issue": "Ofqual, the algorithm used in these results, has shown unfair and biased results",
        "year": "2020",
        "domain": "Education",
        "city": "Coventry",
        "state": "",
        "country": "England",
        "lat": "52.406822",
        "lng": "-1.519693",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/education/2020/aug/07/a-level-result-predictions-to-be-downgraded-england",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "SQA provides students grades",
        "issue": "SQA led to reduced higher pass rates in deprived datazones compared to pupils from affluent backgrounds",
        "year": "2020",
        "domain": "Education",
        "city": "Edinburgh",
        "state": "",
        "country": "Scotland",
        "lat": "55.953251",
        "lng": "-3.188267",
        "is_good": "Harmful",
        "link": "https://www.bbc.com/news/uk-scotland-scotland-politics-53719477",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Proctorio's Racial Bias",
        "issue": "Proctorio, a test proctoring software,reinforces structural oppression such as asking a Black woman to shine more light on her face",
        "year": "2020",
        "domain": "Education",
        "city": "Scottsdale",
        "state": "Arizona",
        "country": "USA",
        "lat": "33.494171",
        "lng": "-111.926048",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2020/08/07/1006132/software-algorithms-proctoring-online-tests-ai-ethics/?utm_medium=tr_social&utm_campaign=site_visitor.unpaid.engagement&utm_source=Twitter#Echobox=1596778938",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Whiteness of AI could \u2018exacerbate racial inequality\u2019, researchers suggest",
        "issue": "The current portrayals and stereotypes about aI risk creates a racially homogenous workforce who create machines with bias in their algorithms",
        "year": "2020",
        "domain": "Society",
        "city": "Cambridge",
        "state": "",
        "country": "England",
        "lat": "52.202541",
        "lng": "0.13124",
        "is_good": "Harmful",
        "link": "https://www.itv.com/news/2020-08-06/whiteness-of-ai-could-exacerbate-racial-inequality-researchers-suggest",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Automation increases gender pay gap",
        "issue": "There is evident bias and discrimination as automation will disproportionately impact women",
        "year": "2020",
        "domain": "Business ",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "51.507351",
        "lng": "-0.127758",
        "is_good": "Harmful",
        "link": "https://www.independent.co.uk/news/uk/home-news/robots-gender-pay-gap-uk-automation-work-a9622511.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Twitter\u2019s algorithm does not seem to silence conservatives",
        "issue": "Twitter recommendation engine favors inflammatory tweets",
        "year": "2020",
        "domain": "Media",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.774929",
        "lng": "-122.419418",
        "is_good": "Harmful",
        "link": "https://www.economist.com/graphic-detail/2020/08/01/twitters-algorithm-does-not-seem-to-silence-conservatives",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Deepfake of Malaysia Minister",
        "issue": "A deepfake of a confession video is used in a sex scandal",
        "year": "2019",
        "domain": "Media",
        "city": "Kuala Lumpur",
        "state": "",
        "country": "Malaysia",
        "lat": "3.15443",
        "lng": "101.7151",
        "is_good": "Harmful",
        "link": "https://www.malaymail.com/news/malaysia/2019/06/12/is-the-political-aide-viral-sex-video-confession-real-or-a-deepfake/1761422",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Flaw in AI Portrait Art",
        "issue": "An app that turns selfies into impressionist portraits whitens skin",
        "year": "2019",
        "domain": "Vision",
        "city": "Boston ",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.360092",
        "lng": "-71.094162",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en_us/article/8xzwgx/racial-bias-in-ai-isnt-getting-better-and-neither-are-researchers-excuses",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Deepfake Audio",
        "issue": "An audio deepfake was created of a CEO asking for money",
        "year": "2020",
        "domain": "Media",
        "city": "Alexandria",
        "state": "Virginia",
        "country": "USA",
        "lat": "38.804836",
        "lng": "-77.046921",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en_us/article/pkyqvb/deepfake-audio-impersonating-ceo-fraud-attempt",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI Hiring Assistant",
        "issue": "An AI hiring firm says it can predict job hopping based on your interviews",
        "year": "2020",
        "domain": "Business ",
        "city": "Cremorne",
        "state": "Victoria",
        "country": "Australia",
        "lat": "-37.82745",
        "lng": "144.990738",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2020/07/24/1005602/ai-hiring-promises-bias-free-job-hopping-prediction/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook ignored racial bias research",
        "issue": "Facebook stopped pursuing topics related to bias",
        "year": "2020",
        "domain": "Society",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.05322",
        "lng": "-122.0322",
        "is_good": "Harmful",
        "link": "https://www.nbcnews.com/tech/tech-news/facebook-management-ignored-internal-research-showing-racial-bias-current-former-n1234746",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Apple co-founder sues YouTube over Bitcoin scam",
        "issue": "Images of Mr.Wozniak were used to steal cryptocurrency",
        "year": "2020",
        "domain": "Media",
        "city": "Mountainview",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "http://bbc.com/news/technology-53512569",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Ripple sues YouTube over cryptocurrency scams",
        "issue": "Scammers used fake social media profiles and dupped victims in cryptocurrency scam",
        "year": "2020",
        "domain": "Media",
        "city": "Mountainview",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.reuters.com/article/us-google-lawsuit-fraud/ripple-sues-youtube-over-cryptocurrency-scams-idUSKCN2232ZY",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Invention of fake journalist by deepfake",
        "issue": "A network of 19 fake journalists have published fake conservative publications",
        "year": "2020",
        "domain": "Media",
        "city": "Jerusalem",
        "state": "",
        "country": "Israel",
        "lat": "31.768318",
        "lng": "35.213711",
        "is_good": "Harmful",
        "link": "https://futurism.com/the-byte/deepfake-fake-journalist",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Algorithmic Bias Detection",
        "issue": "Facebook civil rights audit urges \u2018mandatory\u2019 algorithmic bias detection",
        "year": "2020",
        "domain": "Media",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Harmful",
        "link": "https://venturebeat.com/2020/07/08/facebook-civil-rights-audit-urges-mandatory-algorithmic-bias-detection/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Racial Bias in Face Depixelizer",
        "issue": "An AI tool which reconstructed a pixelated picture of Barack Obama to look like a white man perfectly illustrates racial bias in algorithms",
        "year": "2020",
        "domain": "Vision",
        "city": "Moscow",
        "state": "",
        "country": "Russia",
        "lat": "55.755825",
        "lng": "37.617298",
        "is_good": "Harmful",
        "link": "https://www.businessinsider.com/depixelator-turned-obama-white-illustrates-racial-bias-in-ai-2020-6?r=US&IR=T",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "British police to trial facial recognition system that detects your mood",
        "issue": "This system violates personal privacy by detecting people wearing hats or glasses as well as detecting their mood.",
        "year": "2020",
        "domain": "Vision",
        "city": "Bourne",
        "state": "",
        "country": "England",
        "lat": "52.76825",
        "lng": "-0.37776",
        "is_good": "Harmful",
        "link": "https://thenextweb.com/neural/2020/08/17/british-police-to-trial-facial-recognition-system-that-detects-your-mood/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Uber and Lyft pricing algorithms charge more in non-white areas",
        "issue": "The algorithm creates racial bias because it charges higher prices if the pick up point or destination is in a ethnic minority neighborhood",
        "year": "2020",
        "domain": "Business ",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "36.774929",
        "lng": "-121.019418",
        "is_good": "Harmful",
        "link": "https://www.newscientist.com/article/2246202-uber-and-lyft-pricing-algorithms-charge-more-in-non-white-areas/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Racial bias skews algorithms widely used to guide care from heart surgery to birth, study finds",
        "issue": "The racial bias is created because the algorithm's developers equated higher health care spending with worse health. However, white Americans spend more on healthcare than Black Americans even when their health situation is the same.",
        "year": "2020",
        "domain": "Health Services",
        "city": "Waltham",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.40398",
        "lng": "-71.28278",
        "is_good": "Harmful",
        "link": "https://www.statnews.com/2020/06/17/racial-bias-skews-algorithms-widely-used-to-guide-patient-care/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Amazon deploys AI \u2018distance assistants\u2019 to notify warehouse workers if they get too close",
        "issue": "Employees cannot opt out and this system can violate privacy",
        "year": "2020",
        "domain": "Business ",
        "city": "Seattle ",
        "state": "Washington",
        "country": "USA",
        "lat": "47.623211",
        "lng": "-122.337158",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/2020/6/16/21292669/social-distancing-amazon-ai-assistant-warehouses-covid-19",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook incorrectly removes picture of Aboriginal men in chains because of 'nudity",
        "issue": "Facebook took down photo that revealed history of slavery in Australia",
        "year": "2020",
        "domain": "Media",
        "city": "Canberra",
        "state": "",
        "country": "Australia",
        "lat": "-35.280937",
        "lng": "149.130005",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/technology/2020/jun/13/facebook-incorrectly-removes-picture-of-aboriginal-men-in-chains-because-of-nudity",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Instagram rewards nudity",
        "issue": "Instagram posts featuring 'scantily clad' women are 54% more likely to appear at the top of a user's newsfeed, according to a report that says the firm's algorithm boosts semi-nude images",
        "year": "2020",
        "domain": "Media",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37",
        "lng": "-122",
        "is_good": "Harmful",
        "link": "https://www.dailymail.co.uk/sciencetech/article-8427693/Instagram-posts-scantily-clad-women-54-likely-appear-newsfeeds-report-says.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "OpenAI\u2019s Text Generator Is Going Commercial",
        "issue": "OpenAI text generators are going commercial although they have found models to be unpredictable and could land in bad hands.",
        "year": "2020",
        "domain": "Business ",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.76201",
        "lng": "-122.4165",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/openai-text-generator-going-commercial/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Checkr, AI Background Check, has mistakes",
        "issue": "Companies are utilizing Checkr to hire yet Checkr is not addressing errors and mistakes on criminal records",
        "year": "2020",
        "domain": "Business ",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.06201",
        "lng": "-122.0165",
        "is_good": "Harmful",
        "link": "https://www.vox.com/recode/2020/5/11/21166291/artificial-intelligence-ai-background-check-checkr-fama",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "MHCLG/Faculty COVID-19 data analysis & response",
        "issue": "Contract had a lack of data volume",
        "year": "2020",
        "domain": "Health Services",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "51.007351",
        "lng": "-0.107758",
        "is_good": "Harmful",
        "link": "https://www.contractsfinder.service.gov.uk/Notice/e73b370a-83aa-4944-a06c-70bea0b2c624",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Review of Prediction models for COVID-19",
        "issue": "Proposed models are poorly reported, high risk of bias, and their reported performance is optimistic ",
        "year": "2020",
        "domain": "Health Services",
        "city": "Maastricht",
        "state": "",
        "country": "Netherlands",
        "lat": "50.84985",
        "lng": "5.68726",
        "is_good": "Harmful",
        "link": "https://www.bmj.com/content/369/bmj.m1328",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Enaible tracks office worker's productivity",
        "issue": "Software takes screenshots of computer screens, track keystrokes and compares productivity",
        "year": "2020",
        "domain": "Business ",
        "city": "Dubai",
        "state": "",
        "country": "United Arab Emirates",
        "lat": "25.1059",
        "lng": "55.37556",
        "is_good": "Harmful",
        "link": "https://uk.finance.yahoo.com/news/boss-spying-while-home-211500755.html?guccounter=1",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Microsoft is cutting dozens of MSN news production workers and replacing them with artificial intelligence",
        "issue": "50 employees were fired",
        "year": "2020",
        "domain": "Media",
        "city": "Redmond",
        "state": "Washington",
        "country": "USA",
        "lat": "47.674911",
        "lng": "-122.124001",
        "is_good": "Harmful",
        "link": "https://www.seattletimes.com/business/local-business/microsoft-is-cutting-dozens-of-msn-news-production-workers-and-replacing-them-with-artificial-intelligence/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Everseen, an AI company used by Walmart,  has various errors",
        "issue": "Errors include failing to flag items not being scanned and other false alerts",
        "year": "2020",
        "domain": "Business ",
        "city": "Cork",
        "state": "",
        "country": "Ireland",
        "lat": "51.897869",
        "lng": "-8.47109",
        "is_good": "Harmful",
        "link": "https://arstechnica.com/tech-policy/2020/05/walmart-employees-are-out-to-show-its-anti-shoplifting-ai-doesnt-work/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "CoreLogic: How Automated Background Checks Freeze Out Renters",
        "issue": "The automated background check has many errors. For example, it mismatches information from criminal databases for different people who don't match the user's race, date of birth, middle name, and places where they have lived",
        "year": "2020",
        "domain": "Housing",
        "city": "Irvine",
        "state": "California",
        "country": "USA",
        "lat": "33.684566",
        "lng": "-117.826508",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2020/05/28/business/renters-background-checks.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "RentGrow: How Automated Background Checks Freeze Out Renters",
        "issue": "The automated background check has many errors. For example, it mismatches information from criminal databases for different people who don't match the user's race, date of birth, middle name, and places where they have lived",
        "year": "2020",
        "domain": "Housing",
        "city": "Boston ",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.35899",
        "lng": "-71.05863",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2020/05/28/business/renters-background-checks.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "New Facebook AI project could allow users to insert themselves in other people\u2019s photos",
        "issue": "There could be potential abuse on this platform similar to Deepfakes",
        "year": "2020",
        "domain": "Media",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.05322",
        "lng": "-122.0322",
        "is_good": "Harmful",
        "link": "https://www.standard.co.uk/tech/facebook-ai-insert-pictures-a4453506.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Youtube is automatically deleting comments that criticize the Communist Party",
        "issue": "This error was unnoticed for six months and are repetitive.",
        "year": "2020",
        "domain": "Media",
        "city": "Mountainview",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/2020/5/26/21270290/youtube-deleting-comments-censorship-chinese-communist-party-ccp",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How Tech Companies are Helping Big Oil Profit from Climate Destruction",
        "issue": "Deployment of technology to find more oil",
        "year": "2020",
        "domain": "Society",
        "city": "Houston",
        "state": "Texas",
        "country": "USA",
        "lat": "29.760427",
        "lng": "-95.369804",
        "is_good": "Harmful",
        "link": "https://www.greenpeace.org/usa/reports/oil-in-the-cloud/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "This AI Can Judge Personality Based on Selfies Alone",
        "issue": "There is a potential for racial bias as well as accuracy concerns.",
        "year": "2020",
        "domain": "Society",
        "city": "Moscow ",
        "state": "",
        "country": "Russia",
        "lat": "55.055825",
        "lng": "37.017298",
        "is_good": "Harmful",
        "link": "https://futurism.com/researchers-ai-judge-personality-selfies",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facial Recognition Firms Pitch Covid-19 \u2018Immunity Passports\u2019 For America And Britain",
        "issue": "There is a concern for racial bias and privacy.",
        "year": "2020",
        "domain": "Vision",
        "city": "Encino",
        "state": "California",
        "country": "USA",
        "lat": "34.158852",
        "lng": "-118.499382",
        "is_good": "Harmful",
        "link": "https://www.forbes.com/sites/thomasbrewster/2020/05/20/facial-recognition-firms-pitch-covid-19-immunity-passports-for-america-and-britain/#178701265914",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Masked Selfies from Instagram used for training data for facial recognition",
        "issue": "People did not know their images were used",
        "year": "2020",
        "domain": "Vision",
        "city": "Cambridge ",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "52.20254",
        "lng": "0.13124",
        "is_good": "Harmful",
        "link": "https://www.cnet.com/news/your-face-mask-selfies-could-be-training-the-next-facial-recognition-tool/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "NHS Coronavirus App can allow ministers to de-anonymize users",
        "issue": "The app can allow ministers to identify app users which can violate privacy",
        "year": "2020",
        "domain": "Health Services",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "51.007351",
        "lng": "-0.007758",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/world/2020/apr/13/nhs-coronavirus-app-memo-discussed-giving-ministers-power-to-de-anonymise-users",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Youtube profits from videos promoting unproven Covid-19 treatments",
        "issue": "Youtube is increasing revenue as misinformation increases",
        "year": "2020",
        "domain": "Media",
        "city": "Mountainview",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/technology/2020/apr/03/youtube-coronavirus-treatments-profit-misinformation",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Deepfake of Jay-Z",
        "issue": "A fake audio of Jay-Z rapping \"ToBe, Or Not To Be\"",
        "year": "2020",
        "domain": "Media",
        "city": "Mountainview",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://lbry.tv/@VocalSynthesis:2/jay-z-raps-the-to-be-or-not-to-be:8",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Deepfake of Joe Biden",
        "issue": "A deepfake video that harms Joe Biden which caused a trending of Sloppy Joe",
        "year": "2020",
        "domain": "Media",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.774929",
        "lng": "-122.419418",
        "is_good": "Harmful",
        "link": "https://mobile.twitter.com/realDonaldTrump/status/1254582495951101954?fbclid=IwAR2bYygfS1kLxK5YICujAqYHk8tprPWievNc-wQ5gxasFuRPMvJipkftBf8",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Whole Foods Tracks Unionization Risk",
        "issue": "There is a potential for error and racial bias",
        "year": "2020",
        "domain": "Business ",
        "city": "Austin ",
        "state": "Texas",
        "country": "USA",
        "lat": "30.267153",
        "lng": "-97.743057",
        "is_good": "Harmful",
        "link": "https://www.businessinsider.com/whole-foods-tracks-unionization-risk-with-heat-map-2020-1?r=US&IR=T",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "XR Belgium posts deepfake of Belgian premier linking Covid-19 with climate crisis",
        "issue": "This video shares misinformation",
        "year": "2020",
        "domain": "Media",
        "city": "Brussels",
        "state": "",
        "country": "Belgium",
        "lat": "50.850346",
        "lng": "4.351721",
        "is_good": "Harmful",
        "link": "https://www.brusselstimes.com/news/belgium-all-news/politics/106320/xr-belgium-posts-deepfake-of-belgian-premier-linking-covid-19-with-climate-crisis/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Racial Bias in Vision AI",
        "issue": "A Google service that automatically labels images produced starkly different results depending on skin tone on a given image",
        "year": "2020",
        "domain": "Vision",
        "city": "Mountainview",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://algorithmwatch.org/en/story/google-vision-racism/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Dubious claims that AI outperforms doctors pose risk to \u2018millions of patients,\u2019 study finds",
        "issue": "",
        "year": "2020",
        "domain": "Health Services",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "51.47516",
        "lng": "-0.1871",
        "is_good": "Harmful",
        "link": "https://www.bmj.com/content/368/bmj.m689",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Speech recognition algorithms may also have racial bias",
        "issue": "Error rate for African American speech is nearly double that for others.",
        "year": "2020",
        "domain": "Society",
        "city": "Palo Alto",
        "state": "California",
        "country": "USA",
        "lat": "37.041883",
        "lng": "-122.1003021",
        "is_good": "Harmful",
        "link": "https://arstechnica.com/science/2020/03/speech-recognition-algorithms-may-also-have-racial-bias/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook's Algorithm banned innocent content on masks",
        "issue": "Facebook admits it made an 'error' after algorithm threatens to ban users who post content showing people how to make their own masks",
        "year": "2020",
        "domain": "Society",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Harmful",
        "link": "https://www.dailymail.co.uk/sciencetech/article-8193285/Facebook-admits-error-algorithm-threatens-ban-users-post-content-DIY-masks.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook marks coronavirus posts as spam",
        "issue": "Facebook had an error in their spam detection system which caused news from reputable sources to be flagged",
        "year": "2020",
        "domain": "Society",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37",
        "lng": "-122",
        "is_good": "Harmful",
        "link": "https://www.buzzfeednews.com/article/cameronwilson/facebook-coronavirus-spam-news-posts",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Chinese software dictates quarantine",
        "issue": "There is an invasion of privacy and room for biases",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "Beijing",
        "country": "China",
        "lat": "36.06166",
        "lng": "104.195396",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2020/03/01/business/china-coronavirus-surveillance.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "WeChat has been censoring keywords about coronavirus, study finds",
        "issue": "This censorship caused important information about the coronavirus to not reach people",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "Beijing",
        "country": "China",
        "lat": "35.06166",
        "lng": "104.095396",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/2020/3/3/21163844/wechat-yy-censoring-coronavirus-keywords-citizen-lab",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Mask-Wearers can be ID'd by facial recognition",
        "issue": "Those use case increases surveillance and invades privacy",
        "year": "2020",
        "domain": "Vision",
        "city": "",
        "state": "Beijing",
        "country": "China",
        "lat": "35",
        "lng": "104",
        "is_good": "Harmful",
        "link": "https://www.reuters.com/article/us-health-coronavirus-facial-recognition/china-firm-develops-system-to-recognize-faces-behind-coronavirus-masks-idUSKBN20W0WL",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "US Navy is developing robot submarines controlled by Artificial Intelligence that could be given the 'power to kill' without any human input",
        "issue": "Responsible AI must have human input",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.907192",
        "lng": "-77.036873",
        "is_good": "Harmful",
        "link": "https://www.dailymail.co.uk/sciencetech/article-8091685/US-Navy-developing-robot-submarines-controlled-Artificial-Intelligence.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "\u2018Locate X\u2019 allows federal law enforcement to track phones",
        "issue": "Locate X allows law enforcement to draw digital fence around areas and pinpoint mobile devices used there",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Reston",
        "state": "Virginia",
        "country": "USA",
        "lat": "38.96019",
        "lng": "-77.3545",
        "is_good": "Harmful",
        "link": "https://www.protocol.com/government-buying-location-data",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Clearview AI building surveillance camera",
        "issue": "Clearview AI has already had many errors, privacy issues, and racial bias. Furthermore, the company's data storage and security protocols are untested and unregulated",
        "year": "2020",
        "domain": "Vision",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.907192",
        "lng": "-77.036873",
        "is_good": "Harmful",
        "link": "https://mashable.com/article/clearview-ai-insight-surveillance-camera/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Banjo AI surveillance is already monitoring traffic cams across Utah",
        "issue": "There is a lack of public oversight and rampant potential for abuse. The facial recognition is also biased.",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Salt Lake City",
        "state": "Utah",
        "country": "USA",
        "lat": "40.75848",
        "lng": "-111.888138",
        "is_good": "Harmful",
        "link": "https://www.engadget.com/2020-03-04-banjo-ai-utah-law-enforcement-surveillance.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Momus Analytics",
        "issue": "Momus Analytics' predictive scoring system is using race to grade potential jurors on vague qualities like \"leadership\" and \"personal responsibility.\"",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Coral Gables",
        "state": "Florida",
        "country": "USA",
        "lat": "25.710291",
        "lng": "-80.268646",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en_us/article/epgmbw/this-company-is-using-racially-biased-algorithms-to-select-jurors",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "California Police Have Been Illegally Sharing License Plate Reader Data",
        "issue": "The use of automated license plate readers collected data on innocent people and car movements which was shared",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Los Angeles",
        "state": "California",
        "country": "USA",
        "lat": "34.052235",
        "lng": "-118.243683",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en_us/article/y3mb8b/california-police-have-been-illegally-sharing-license-plate-reader-data",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Twitter verifies fake congressional candidate",
        "issue": "This instance contributes to the rise of misinformation",
        "year": "2020",
        "domain": "Media",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.974929",
        "lng": "-122.019418",
        "is_good": "Harmful",
        "link": "https://futurism.com/the-byte/twitter-verifies-fake-congressional-candidate",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Revealed: quarter of all tweets about climate crisis produced by bots",
        "issue": "Brown University study found the substantial impact of bots amplifying denialist messages which increases misinformation",
        "year": "2020",
        "domain": "Media",
        "city": "Providence",
        "state": "Rhode Island",
        "country": "USA",
        "lat": "41.826771",
        "lng": "-71.40255",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/technology/2020/feb/21/climate-tweets-twitter-bots-analysis",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "EU Collection of Biometric Data for Facial Recognition Plan",
        "issue": "There is a secretive nature to this plan as well as could amplify racial biases",
        "year": "2020",
        "domain": "Vision",
        "city": "Brussels",
        "state": "",
        "country": "Belgium",
        "lat": "50.00346",
        "lng": "4.051721",
        "is_good": "Harmful",
        "link": "https://theintercept.com/2020/02/21/eu-facial-recognition-database/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How Allstate\u2019s Secret Auto Insurance Algorithm Squeezes Big Spenders",
        "issue": "Algorithm denies meaningful decreases to thousands of Allstate customers who are paying too much and encourages price increases",
        "year": "2020",
        "domain": "Business ",
        "city": "Northfield Township",
        "state": "Illinois",
        "country": "USA",
        "lat": "42.10162",
        "lng": "-87.77666",
        "is_good": "Harmful",
        "link": "https://themarkup.org/allstates-algorithm/2020/02/25/car-insurance-suckers-list",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Barclays installs Big Brother-style spyware on employees' computers",
        "issue": "This pilot violates privacy, encourages intimidation and has room for racial bias",
        "year": "2020",
        "domain": "Business ",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "51.07516",
        "lng": "-0.0071",
        "is_good": "Harmful",
        "link": "https://www.cityam.com/exclusive-barclays-installs-big-brother-style-spyware-on-employees-computers/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Deepfakes in Indian Election",
        "issue": "President Manoj Tiwari criticized Arvind Kejriwal. However, the videos are not real and lead to misinformation",
        "year": "2020",
        "domain": "Media",
        "city": "Delhi",
        "state": "",
        "country": "India",
        "lat": "28.632429",
        "lng": "77.218788",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en_in/article/jgedjb/the-first-use-of-deepfakes-in-indian-election-by-bjp",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Scotland plans to use facial recognition",
        "issue": "The use of facial recognition has racial bias and can be used unfairly",
        "year": "2020",
        "domain": "Vision",
        "city": "Edinburgh",
        "state": "",
        "country": "Scotland",
        "lat": "55.953251",
        "lng": "-3.188267",
        "is_good": "Harmful",
        "link": "https://www.bbc.com/news/uk-scotland-51449166",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Russia's use of facial recognition challenged in court",
        "issue": "Russian authorities are using facial recognition at mass gatherings and collecting all personal data",
        "year": "2020",
        "domain": "Vision",
        "city": "Moscow ",
        "state": "",
        "country": "Russia",
        "lat": "54.855825",
        "lng": "37.000298",
        "is_good": "Harmful",
        "link": "https://www.bbc.com/news/technology-51324841",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "NotRealNews.net",
        "issue": "Fake News could be spread by bots and lead to misinformation",
        "year": "2020",
        "domain": "Media",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.974929",
        "lng": "-122.019418",
        "is_good": "Harmful",
        "link": "https://futurism.com/site-ai-generate-fake-news-articles",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "UK DWP Immigration Status",
        "issue": "Secretive\u2019 settled-status algorithm risks discrimination",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "50.97516",
        "lng": "0",
        "is_good": "Harmful",
        "link": "https://www.ft.com/content/404e36b6-2569-11ea-9305-4234e74b0ef3",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Blackbox welfare fraud detection system breaches human rights",
        "issue": "An algorithmic risk scoring system that predicts the likelihood of social security claimants that will commit benefits or tax fraud is a breach of human rights because it targets low income and minority residents neighborhoods",
        "year": "2020",
        "domain": "Society",
        "city": "Amsterdam",
        "state": "",
        "country": "Netherlands",
        "lat": "52.370216",
        "lng": "4.895168",
        "is_good": "Harmful",
        "link": "https://techcrunch.com/2020/02/06/blackbox-welfare-fraud-detection-system-breaches-human-rights-dutch-court-rules/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "YouTube faces criticism over climate misinformation",
        "issue": "Youtube's algorithm encourages misinformation by adding climate disinformation videos in \"up next\" feature",
        "year": "2020",
        "domain": "Media",
        "city": "Mountainview",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.axios.com/youtube-climate-change-misinformation-8ff85b76-285b-4b40-b8ca-2b7211246fb2.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Bernie Sanders Deepfake",
        "issue": "A deepfake of Bernie Sanders spreads misinformation and defamation",
        "year": "2020",
        "domain": "Media",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "38.074929",
        "lng": "-122.019418",
        "is_good": "Harmful",
        "link": "https://twitter.com/datashade/status/1220198019754315776",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Miami Police Used Facial Recognition Technology in Protester's Arrest",
        "issue": "The use of facial recognition during protesting violates freedom of speech as well as leaves room for racial bias",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Miami",
        "state": "Florida",
        "country": "USA",
        "lat": "25.761681",
        "lng": "-80.191788",
        "is_good": "Harmful",
        "link": "https://www.nbcmiami.com/investigations/miami-police-used-facial-recognition-technology-in-protesters-arrest/2278848/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Gigapixel AI Accidentally Added Ryan Gosling\u2019s Face to This Photo",
        "issue": "This could lead to an increase of misinformation and defamation",
        "year": "2020",
        "domain": "Media",
        "city": "Dallas ",
        "state": "Texas",
        "country": "USA",
        "lat": "32.779167",
        "lng": "-96.808891",
        "is_good": "Harmful",
        "link": "https://petapixel.com/2020/08/17/gigapixel-ai-accidentally-added-ryan-goslings-face-to-this-photo/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI experts doubt Amazon's new Halo wearable can accurately judge the emotion in your voice, and worry about the privacy risks",
        "issue": "This product uses machine learning to detect emotion. However, a machine analyzing something as complex as a human emotion correctly is unlikely and there are many data privacy concerns.",
        "year": "2020",
        "domain": "Society",
        "city": "Seattle",
        "state": "Washington",
        "country": "USA",
        "lat": "47.606209",
        "lng": "-122.332069",
        "is_good": "Harmful",
        "link": "https://www.businessinsider.com/experts-skeptical-amazon-halo-judges-emotional-state-from-voice-2020-8?r=US&IR=T",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Tinder has charged Plus users differently based on age,gender, sexuality and location in Australia",
        "issue": "When a company charges based on inherent human features, this increases racial and gender bias. ",
        "year": "2020",
        "domain": "Society",
        "city": "Canberra",
        "state": "",
        "country": "Australia",
        "lat": "-35.280937",
        "lng": "149.130005",
        "is_good": "Harmful",
        "link": "https://www.gizmodo.com.au/2020/08/apps-can-charge-you-more-based-on-sex-and-location-and-legislation-cant-keep-up/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A face-scanning algorithm increasingly decides whether you deserve the job",
        "issue": "A human should always be involved in making important decisions such as hiring. Hirevue uses AI-driven assessments only.",
        "year": "2019",
        "domain": "Business",
        "city": "South Jordan",
        "state": "Utah",
        "country": "USA",
        "lat": "40.559311",
        "lng": "-111.938667",
        "is_good": "Harmful",
        "link": "https://www.washingtonpost.com/technology/2019/10/22/ai-hiring-face-scanning-algorithm-increasingly-decides-whether-you-deserve-job/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A Dogfight Renews Concerns About AI's Lethal Potential",
        "issue": "Heron developed its own reinforcement learning algorithm from scratch and won a dogfight contest, a competition where AI is used for mission-critical military tasks that were once done only by humans",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Lexington Park",
        "state": "Maryland",
        "country": "USA",
        "lat": "38.29001",
        "lng": "-76.49224",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/dogfight-renews-concerns-ai-lethal-potential/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "House Building algorithm unfair to towns and cities, Boris Johnson told",
        "issue": "The algorithm risks \"levelling down\" city and town centres.",
        "year": "2020",
        "domain": "Housing",
        "city": "",
        "state": "London",
        "country": "England",
        "lat": "51.507351",
        "lng": "-0.127758",
        "is_good": "Harmful",
        "link": "https://www.thetimes.co.uk/edition/news/housebuilding-algorithm-unfair-to-towns-and-cities-boris-johnson-warned-7v0zz6hvz",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Councils scrapping use of algorithms in benefit and welfare decisions",
        "issue": "After a lack of transparency in welfare decisions, 20 councils stopped using computer algorithms",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "London",
        "country": "England",
        "lat": "51.507351",
        "lng": "-0.127758",
        "is_good": "Helpful",
        "link": "https://www.theguardian.com/society/2020/aug/24/councils-scrapping-algorithms-benefit-welfare-decisions-concerns-bias",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Machine-Learning Driven Drug Repurposing for SARS-CoV-2",
        "issue": " This team used machine learning to find drug repurposing for SARS-Co-V-2. The findings in this article should not be used to guide clinical decision-making, nor do these findings identify a definitive treatment for COVID-19.",
        "year": "2020",
        "domain": "Health Services",
        "city": "",
        "state": "Pointe Claire",
        "country": "Canada",
        "lat": "45.46578",
        "lng": "45.46578",
        "is_good": "Helpful",
        "link": "https://medium.com/zetane-blog/machine-learning-driven-drug-repurposing-for-sars-cov-2-eedc0a83c38",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A-level and GCSE results in England to be based on teacher assessments in U-turn",
        "issue": "After 40% of predicted results were downgraded, the government decided to award grades on the basis of what teachers submitted.",
        "year": "2020",
        "domain": "Education",
        "city": "",
        "state": "London",
        "country": "England",
        "lat": "50.97516",
        "lng": "0",
        "is_good": "Helpful",
        "link": "https://www.theguardian.com/education/2020/aug/17/a-levels-gcse-results-england-based-teacher-assessments-government-u-turn",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Robinhood could face over $10M in SEC fine for high-speed trade deals",
        "issue": "Robinhood utilized high-speed trading (using computer programs to transact a large number of orders in fraction of seconds) without fully disclosing to clients",
        "year": "2020",
        "domain": "Business",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45431",
        "lng": "-122.16518",
        "is_good": "Harmful",
        "link": "https://www.usatoday.com/story/money/2020/09/02/robinhood-trading-app-could-face-over-10-m-sec-fine/5697720002/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Why smart home devices and feminized A.I. need a rethink.",
        "issue": "Various technologies represent stereotypes about women",
        "year": "2020",
        "domain": "Society",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.974929",
        "lng": "-122.019418",
        "is_good": "Harmful",
        "link": "https://slate.com/technology/2020/09/gender-smart-home-devices-artificial-intelligence-wives.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A Human Rights Analysis of Algorithmic Policing in Canada",
        "issue": "Algorithmic Policing causes implications for rights of privacy, right to freedom of expression, peaceful assembly and association",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "Toronto",
        "country": "Canada",
        "lat": "43.661369",
        "lng": "-79.396263",
        "is_good": "Harmful",
        "link": "https://citizenlab.ca/2020/09/to-surveil-and-predict-a-human-rights-analysis-of-algorithmic-policing-in-canada/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Healthcare Algorithms Are Biased, and the Results Can Be Deadly",
        "issue": "These discrepancies can be extremely harmful to the patient/client when making decisions about treatment and diagnosis.",
        "year": "2020",
        "domain": "Health Services",
        "city": "Berkeley",
        "state": "California",
        "country": "USA",
        "lat": "37.871593",
        "lng": "-122.272743",
        "is_good": "Harmful",
        "link": "https://www.pcmag.com/opinions/healthcare-algorithms-are-biased-and-the-results-can-be-deadly",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Millions of black people affected by racial bias in health-care algorithms",
        "issue": "Studies revealed that algorithms in hospitals and health insurance have biases against black people.  ",
        "year": "2019",
        "domain": "Health Services",
        "city": "Berkeley",
        "state": "California",
        "country": "USA",
        "lat": "37.871593",
        "lng": "-122.272743",
        "is_good": "Harmful",
        "link": "https://www.nature.com/articles/d41586-019-03228-6",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Three Insights From Google's 'Failed' Field Test To Use AI For Medical Diagnosis",
        "issue": "A new AI deep learning model that detects diabetic retinopathy failed during its first field test.  Where some were quick to call this a failure, this article argues that because the field test revealed flaws in the technology, the failure is good because it brings them closer to a better solution.",
        "year": "2020",
        "domain": "Health Services",
        "city": "Mountainview",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.forbes.com/sites/forbestechcouncil/2020/06/09/three-insights-from-googles-failed-field-test-to-use-ai-for-medical-diagnosis/#667e5deabac4",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI Models Trained On Sex Biased Data Performed Worse At Diagnosing Disease",
        "issue": "A study in Argentina revealed that training data tended to be sex-skewed because women were excluded partly or entirely from training models (the same would be true if men were excluded more than women. \nThis makes diagnosing and treating women less accurate than men.",
        "year": "2020",
        "domain": "Health Services",
        "city": "Palo Alto",
        "state": "California",
        "country": "USA",
        "lat": "37.424107",
        "lng": "-122.166077",
        "is_good": "Harmful",
        "link": "https://www.unite.ai/ai-models-trained-on-sex-biased-data-perform-worse-at-diagnosing-disease/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A Biased Medical Algorithm Favored White People for Healthcare Programs",
        "issue": "The algorithm prioritized white people over black people when predicting which patients would need extra healthcare.  Socioeconomics (and other unspecified reasons) affected the training data because black patients have historically recieved lower health care costs than white people for the same treatment.  This causes the algorithm to give a white person and a black person the same score even though the black person is far sicker.  Researchers found that white patients were given higher risk scores, and were therefore more likely to be selected for extra care than black patients who were, in fact, equally sick; they calculated that the bias cut the proportion of black patients who got extra help by more than half.",
        "year": "2019",
        "domain": "Health Services",
        "city": "Berkeley",
        "state": "California",
        "country": "USA",
        "lat": "37.871593",
        "lng": "-122.272743",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2019/10/25/132184/a-biased-medical-algorithm-favored-white-people-for-healthcare-programs/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI and tackling the issue of \"digital redlining\"",
        "issue": "System for identifying people whose benefits applications required extra scrutiny was only deployed on poorer neighborhoods. Therefore, the system discriminated against certain socioeconomic statuses, religions, and ethnicities. ",
        "year": "2020",
        "domain": "Housing",
        "city": "",
        "state": "The Hague",
        "country": "Netherlands",
        "lat": "52.377956",
        "lng": "4.89707",
        "is_good": "Harmful",
        "link": "https://fortune.com/2020/02/11/a-i-fairness-eye-on-a-i/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Racial Bias Found in Major Health Care Risk Algorithm",
        "issue": "A program was found to be influenced by the correlation between race and income. Because of systematic racism, this resulted in black patients receiving lower quality care.",
        "year": "2019",
        "domain": "Health Services",
        "city": "Berkeley",
        "state": "California",
        "country": "USA",
        "lat": "37.871593",
        "lng": "-122.272743",
        "is_good": "Harmful",
        "link": "https://www.scientificamerican.com/article/racial-bias-found-in-a-major-health-care-risk-algorithm/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Why Artificial Intelligence Is Not Ready For Healthcare",
        "issue": "AI needs to be accurate, unbiased, and trustworthy before it can be used in healthcare.",
        "year": "2020",
        "domain": "Health Services",
        "city": "Nashville",
        "state": "Tennessee",
        "country": "USA",
        "lat": "36.174465",
        "lng": "-86.76796",
        "is_good": "Harmful",
        "link": "https://www.madinamerica.com/2020/05/artificial-intelligence-not-ready-healthcare/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Discrimination By Artificial Intelligence in A Commercial Electronic Health Record - A Case Study",
        "issue": "The article questions algorithms' use of patients' personal information and questions how it plays a role in creating biased AI. A model for predicting \u201cno shows\u201d serves as an example for AI systems that are biased, inaccurate, or untrustworthy.",
        "year": "2020",
        "domain": "Health Services",
        "city": "Silver Spring",
        "state": "Maryland",
        "country": "USA",
        "lat": "38.994373",
        "lng": "-77.029778",
        "is_good": "Harmful",
        "link": "https://www.healthaffairs.org/do/10.1377/hblog20200128.626576/full/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Senators Urge Health Orgs to Combat Racial Bias in AI Algorithms",
        "issue": "Several senators urged the Trump administration and major healthcare companies to address racial bias in AI after a study came out that proved black americans were less likely to receive additional healthcare services. The creators of the algorithm didn't account for factors, such as barriers when accessing care, when programing the AI.",
        "year": "2019",
        "domain": "Health Services",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.889248",
        "lng": "-77.050636",
        "is_good": "Harmful",
        "link": "https://www.healthcareitnews.com/news/senators-urge-health-orgs-combat-racial-bias-ai-algorithms",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Don't trust deep learning algos to touch up medical scans: Boffins warn 'highly unstable' tech leads to bad diagnosis",
        "issue": "Some medical imaging can be untrustworthy, and some algorithms are unstable; this article argues that AI is not accurate enough for medicine. Flaws in medical imaging can lead to false diagnosis and further medical issues",
        "year": "2020",
        "domain": "Health Services",
        "city": "Palo Alto",
        "state": "California",
        "country": "USA",
        "lat": "37.424107",
        "lng": "-122.166077",
        "is_good": "Harmful",
        "link": "https://www.theregister.com/2020/05/13/ai_medical_scans/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Twitter tests for bias before shipping model and plans to open-source solution to racial bias in picture-cropping algorithm",
        "issue": "There is a current investigation after Twitter users found that Black faces were less likely to be shown than white ones on image previews on mobile when an image has a Black and a White face.",
        "year": "2020",
        "domain": "Media",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.7749",
        "lng": "-122.4194",
        "is_good": "Helpful",
        "link": "nbcnews.com/tech/tech-news/twitter-investigate-racial-bias-picture-cropping-algorithm-rcna130",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook makes strides using A.I. to automatically find hate speech and COVID-19 misinformation",
        "issue": "Facebook started publishing Community Standards Enforcement Report and built various AI techniques to police content ",
        "year": "2020",
        "domain": "Media",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Helpful",
        "link": "https://fortune.com/2020/05/12/facebook-a-i-hate-speech-covid-19-misinformation/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Could AI spell the end of money laundering?",
        "issue": "AI can help identify suspicious activity at large volume which can help with anti-money laundering efforts",
        "year": "2019",
        "domain": "Business",
        "city": "San Jose",
        "state": "California",
        "country": "USA",
        "lat": "37.33548",
        "lng": "-121.893",
        "is_good": "Helpful",
        "link": "https://www.itproportal.com/features/could-ai-spell-the-end-of-money-laundering/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Deltec Bank, Bahamas says, Artificial Intelligence Will Improve the Banking Experience with Predictive Analytics",
        "issue": "Banks could use predictive analytics for fraud prevention and customer focused solutions",
        "year": "2020",
        "domain": "Business",
        "city": "Nassau",
        "state": "",
        "country": "Bahamas",
        "lat": "25.0599",
        "lng": "-77.345",
        "is_good": "Helpful",
        "link": "http://www.digitaljournal.com/pr/4665575",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI to aid early engagement with equity release clients",
        "issue": "AI could help answer any questions that customers want to be anonymous. Furthermore, they can work together with human advisers once the customer is ready for a face to face meeting",
        "year": "2020",
        "domain": "Business",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "51.507351",
        "lng": "-0.1",
        "is_good": "Helpful",
        "link": "https://www.ftadviser.com/mortgages/2020/04/27/ai-to-aid-early-engagement-with-equity-release-clients/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Making news not faking news: Watch Deepfake Donald Trump on his first day working at RT",
        "issue": "The prevalence of deepfakes adds misinformation and mistrust to the American election.",
        "year": "2020",
        "domain": "Media",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.007192",
        "lng": "-77.036873",
        "is_good": "Harmful",
        "link": "https://www.rt.com/news/501369-donald-trump-rt-deep-fake/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook removes Chinese network of fake accounts that used AI-generated faces",
        "issue": "Facebook removed fake accounts that added to government propaganda about the South China Sea",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "South China Sea",
        "country": "China",
        "lat": "15.488092",
        "lng": "114.404755",
        "is_good": "Helpful",
        "link": "https://thenextweb.com/neural/2020/09/23/facebook-removes-chinese-network-of-fake-accounts-that-used-ai-generated-faces/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A controversial photo editing app slammed for AI-enabled 'blackface' feature",
        "issue": "Gradient, a photo editing apps, lets people alter ethnicity which some users have used as blackface",
        "year": "2020",
        "domain": "Media",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.974929",
        "lng": "-122.019418",
        "is_good": "Harmful",
        "link": "https://edition.cnn.com/2020/09/23/tech/gradient-app-ai-blackface/index.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Despite past denials, LAPD has used facial recognition software 30,000 times in last decade, records show",
        "issue": "The facial recognition software can provide vague and contradictory information",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Los Angeles",
        "state": "California",
        "country": "USA",
        "lat": "34.052235",
        "lng": "-118.243683",
        "is_good": "Harmful",
        "link": "https://www.latimes.com/california/story/2020-09-21/lapd-controversial-facial-recognition-software",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Meituan, Ele.me relax food delivery time targets after backlash over risky conditions for riders",
        "issue": "AI was used to increase efficiency. However, after causing drivers to risk their lives even in extreme weather conditions, Meituan added eight minutes to dispatch time and Ele.me added 10 minutes and more.",
        "year": "2020",
        "domain": "Business",
        "city": "",
        "state": "Beijing",
        "country": "China",
        "lat": "35.86166",
        "lng": "104.195396",
        "is_good": "Helpful",
        "link": "https://www.scmp.com/tech/apps-social/article/3100847/eleme-give-customers-option-wait-longer-food-deliveries-after",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Addison Rae & Dixie D'Amelio spooked by 'deepfakes' of them on TikTok",
        "issue": "Deepfakes can spread information and sexualize young female minors,",
        "year": "2020",
        "domain": "Media",
        "city": "Los Angeles",
        "state": "California",
        "country": "USA",
        "lat": "34.002235",
        "lng": "-118.043683",
        "is_good": "Harmful",
        "link": "https://www.dexerto.com/entertainment/addison-rae-dixie-damelio-spooked-by-deepfakes-of-them-on-tiktok-1420304",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "At an Outback Steakhouse Franchise, Surveillance Blooms",
        "issue": "The restaurant started using Preso Vision, a computer vision program, to maximize employee efficiency and performance.",
        "year": "2019",
        "domain": "Business",
        "city": "Portland",
        "state": "Oregon",
        "country": "USA",
        "lat": "45.523064",
        "lng": "-122.676483",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/outback-steakhouse-presto-vision-surveillance/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google Instant's Allegedly 'Anti-Semitic' Results Lead To Lawsuit In France",
        "issue": "SOS Racisme, Union of Jewish Students of France, the Movement Against Racism and For Friendship Among People sued Google because it suggests the word \"Jewish\" for certain celebrities.",
        "year": "2012",
        "domain": "Society",
        "city": "",
        "state": "Paris",
        "country": "France",
        "lat": "48.864716",
        "lng": "2.349014",
        "is_good": "Harmful",
        "link": "https://www.huffingtonpost.co.uk/entry/google-instant-anti-semitic-france_n_1465430?ri18n=true&guccounter=1",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google ordered to change autocomplete function in Japan",
        "issue": "Google autocomplete affected a man's reputation in employment after suggesting crimes he was not a part of",
        "year": "2012",
        "domain": "Business",
        "city": "",
        "state": "Tokyo",
        "country": "Japan",
        "lat": "35.058581",
        "lng": "139.745438",
        "is_good": "Harmful",
        "link": "https://www.bbc.com/news/technology-17510651",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Missed Connections: What search engines say about women",
        "issue": "Search Engine results maintain unequal access and perpetuate stereotypes especially for black women",
        "year": "2012",
        "domain": "Society",
        "city": "",
        "state": "Portland",
        "country": "Oregon",
        "lat": "45.33064",
        "lng": "-122.076483",
        "is_good": "Harmful",
        "link": "https://safiyaunoble.files.wordpress.com/2012/03/54_search_engines.pdf",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google searches expose racial bias, says study of names",
        "issue": "Names typically associated with black people are more likely to produce ads related to criminal activity",
        "year": "2013",
        "domain": "Society",
        "city": "Boston",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.362293",
        "lng": "-71.059893",
        "is_good": "Harmful",
        "link": "https://www.bbc.com/news/technology-21322183",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "English language tests: MPs to hold inquiry into cheating row",
        "issue": "AI sued in language test cheating to reduce visa application fraud. However, 97% of applications were deemed suspicious ",
        "year": "2014",
        "domain": "Society",
        "city": "",
        "state": "London",
        "country": "England",
        "lat": "51.507351",
        "lng": "-0.127758",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/uk-news/2019/jun/27/commons-inquiry-foreign-students-accused-cheating-sajid-javid",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook to pay $550 million to settle privacy lawsuit over facial recognition tech",
        "issue": "Facebook Tag Suggestions tool stores biometric data without user consent",
        "year": "2015",
        "domain": "Vision",
        "city": "Springfield",
        "state": "Illinois",
        "country": "USA",
        "lat": "39.99936",
        "lng": "-89.04362",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/2020/1/29/21114358/facebook-550-million-settle-lawsuit-facial-recognition-technology-illinois",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Why and How Baidu Cheated an Artificial Intelligence Test",
        "issue": "Baidu claimed to meet Google on image recognition software by cheating",
        "year": "2015",
        "domain": "Vision",
        "city": "",
        "state": "Beijing",
        "country": "China",
        "lat": "35.86166",
        "lng": "104.195396",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2015/06/04/72951/why-and-how-baidu-cheated-an-artificial-intelligence-test/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google Photos identified two black people as 'gorillas'",
        "issue": "Google Photos App tagged Black people as gorillas.",
        "year": "2015",
        "domain": "Vision",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://mashable.com/2015/07/01/google-photos-black-people-gorillas/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "The Hidden Gender Bias in Google Image Search",
        "issue": "A comparative analysis to see the prevalence of gender in different professions showed that Google perpetuated societal stereotypes ",
        "year": "2015",
        "domain": "Vision",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.fastcompany.com/3045295/the-hidden-gender-bias-in-google-image-search",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Singapore in world first for facial verification",
        "issue": "Singapore is using a biometric check to secure access to both private and government services. The system has checked if the person is authentically there, a photograph, video, or deepfake cannot be used. ",
        "year": "2020",
        "domain": "Vision",
        "city": "",
        "state": "Singapore",
        "country": "Singapore",
        "lat": "1.29",
        "lng": "103.85",
        "is_good": "Helpful",
        "link": "https://yro.slashdot.org/story/20/09/27/0843255/singapore-becomes-first-country-to-use-facial-verification-for-a-national-id-service?utm_source=angellist",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How Algorithms Can Fight Bias Instead of Entrench It",
        "issue": "We can use boxing, multiple ranking ordering scores, and other methods to fight bias in AI",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "Cambridge",
        "country": "England",
        "lat": "53.72377",
        "lng": "-1.35426",
        "is_good": "Helpful",
        "link": "https://behavioralscientist.org/how-algorithms-can-fight-bias-instead-of-entrench-it/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How an AI tool for fighting hospital deaths actually worked in the real world",
        "issue": "Sepsis Watch, a deep learning tool, reduced sepsis-induced patient deaths because of nurse feedback and local and specific context",
        "year": "2020",
        "domain": "Health Services",
        "city": "Durham",
        "state": "North Carolina",
        "country": "USA",
        "lat": "35.994034",
        "lng": "-78.898621",
        "is_good": "Helpful",
        "link": "https://www.technologyreview.com/2020/10/02/1009267/ai-reduced-hospital-deaths-in-the-real-world/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A New Program Judges If You're a Criminal From Your Facial Features",
        "issue": "The \"Automated Inference on Criminality using Face Images\" declares that a computer can detect if a human could be a convicted criminal based on facial features. ",
        "year": "2016",
        "domain": "Law Enforcement",
        "city": "Shanghai",
        "state": "",
        "country": "China",
        "lat": "31.025486",
        "lng": "121.43",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en/article/d7ykmw/new-program-decides-criminality-from-facial-features",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google\u2019s top results for \u2018Did the Holocaust happen\u2019 now expunged of denial sites",
        "issue": "The search engine results lead to misinformation about the Holocaust.",
        "year": "2016",
        "domain": "Society",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.006051",
        "lng": "-122.003855",
        "is_good": "Harmful",
        "link": "https://searchengineland.com/google-holocaust-denial-site-gone-266353",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How Facebook\u2019s Ad Tool Fails to Protect Civil Rights",
        "issue": "Facebook lets advertisers exclude people of certain races from seeing ads.",
        "year": "2016",
        "domain": "Housing",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Harmful",
        "link": "https://www.theatlantic.com/business/archive/2016/10/facebook-ad-discrimination/505703/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Study Casts Doubt on Chicago Police\u2019s Secretive \u201cHeat List\u201d",
        "issue": "Chicago Police Department uses big data to predict crimes, but there is no way to independently verify their claims.",
        "year": "2016",
        "domain": "Law Enforcement",
        "city": "Chicago",
        "state": "Illinois",
        "country": "USA",
        "lat": "41.883228",
        "lng": "-87.632401",
        "is_good": "Harmful",
        "link": "https://www.chicagomag.com/city-life/August-2016/Chicago-Police-Data/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Bodega, once dubbed \u2018America\u2019s most hated startup,\u2019 has quietly raised millions",
        "issue": "Bodega, a vending machine with AI, received criticism because of misappropriation. Since then, it has changed its name to Stockwell.",
        "year": "2017",
        "domain": "Business",
        "city": "Oakland ",
        "state": "California",
        "country": "USA",
        "lat": "37.804363",
        "lng": "-122.271111",
        "is_good": "Helpful",
        "link": "https://techcrunch.com/2019/09/25/bodega-once-dubbed-americas-most-hated-startup-has-quietly-raised-millions/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How a Chinese AI Giant Made Chatting\u2014and Surveillance\u2014Easy",
        "issue": "iFlytek enables surveillance with the Chinese government through smart assistants.",
        "year": "2020",
        "domain": "Society",
        "city": "Hefei",
        "state": "Anhu",
        "country": "China",
        "lat": "31.848398",
        "lng": "117.272362",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/iflytek-china-ai-giant-voice-chatting-surveillance/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "MIT Cuts Ties With a Chinese AI Firm Amid Human Rights Concerns",
        "issue": "MIT cuts funding of iFlytek technology that is being used to oppress ethnic Uighurs in China's northwest",
        "year": "2020",
        "domain": "Society",
        "city": "Cambridge",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.060001",
        "lng": "-71.002003",
        "is_good": "Helpful",
        "link": "https://www.wired.com/story/mit-cuts-ties-chinese-ai-firm-human-rights/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google\u2019s DeepMind and UK hospitals made illegal deal for health data, says watchdog",
        "issue": "DeepMind processed 1.6 million patients belonging to UK citizens for the Royal Free Trust.",
        "year": "2017",
        "domain": "Health Services",
        "city": "",
        "state": "London",
        "country": "England",
        "lat": "51.007351",
        "lng": "-0.187758",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/2017/7/3/15900670/google-deepmind-royal-free-2015-data-deal-ico-ruling-illegal",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Woman In China Says Colleague\u2019s Face Was Able To Unlock Her iPhone X",
        "issue": "A woman is not recognized on facial recognition",
        "year": "2017",
        "domain": "Vision",
        "city": "Nanjing",
        "state": "",
        "country": "China",
        "lat": "32.049999",
        "lng": "118.76667",
        "is_good": "Harmful",
        "link": "https://www.huffingtonpost.co.uk/entry/iphone-face-recognition-double_n_5a332cbce4b0ff955ad17d50?ri18n=true",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "FaceApp apologises for 'racist' filter that lightens users' skin tone",
        "issue": "FaceApp hot filter (neural networks) automatically lightened people's skin",
        "year": "2017",
        "domain": "Society",
        "city": "St.Petersburg",
        "state": "",
        "country": "Russia",
        "lat": "59.9375",
        "lng": "30.308611",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/technology/2017/apr/25/faceapp-apologises-for-racist-filter-which-lightens-users-skintone",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "HSBC voice recognition system breached by customer's twin",
        "issue": "Voice recognition system is breached by twin",
        "year": "2017",
        "domain": "Business",
        "city": "",
        "state": "London",
        "country": "England",
        "lat": "51.507351",
        "lng": "-0.127758",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/business/2017/may/19/hsbc-voice-recognition-system-breached-by-customers-twin",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Is YouTube's Algorithm Endangering Kids?",
        "issue": "When a user types in \"How to have\", the autocomplete result is \"How to have sex with kids\"",
        "year": "2017",
        "domain": "Society",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.006051",
        "lng": "-122.003855",
        "is_good": "Harmful",
        "link": "https://www.npr.org/sections/thetwo-way/2017/11/27/566769570/youtube-faces-increased-criticism-that-its-unsafe-for-kids",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook translates 'good morning' into 'attack them', leading to arrest",
        "issue": "A man is questioned after an mistranslation of caption",
        "year": "2017",
        "domain": "Law Enforcement",
        "city": "Ramallah",
        "state": "",
        "country": "Palestine",
        "lat": "31.898043",
        "lng": "35.204269",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/technology/2017/oct/24/facebook-palestine-israel-translates-good-morning-attack-them-arrest",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Deal or no deal? Training AI bots to negotiate",
        "issue": "The ability for bots to invest into a new language through reinforcement learning could lead to unintended consequences",
        "year": "2017",
        "domain": "Business",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Harmful",
        "link": "https://engineering.fb.com/ml-applications/deal-or-no-deal-training-ai-bots-to-negotiate/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Study Finds Car Insurers Raise Rates in Minority Neighborhoods",
        "issue": "Major insurers charged premiums that were 30% higher in minority ZIP codes than in non minority neighborhoods",
        "year": "2017",
        "domain": "Housing",
        "city": "Sacramento",
        "state": "California",
        "country": "USA",
        "lat": "38.575764",
        "lng": "-121.478851",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2017/04/05/your-money/minorities-car-insurance-rates.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Study Finds Car Insurers Raise Rates in Minority Neighborhoods",
        "issue": "Major insurers charged premiums that were 30% higher in minority ZIP codes than in non minority neighborhoods",
        "year": "2017",
        "domain": "Housing",
        "city": "Springfield",
        "state": "Illinois",
        "country": "USA",
        "lat": "39.799999",
        "lng": "-89.650002",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2017/04/05/your-money/minorities-car-insurance-rates.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Study Finds Car Insurers Raise Rates in Minority Neighborhoods",
        "issue": "Major insurers charged premiums that were 30% higher in minority ZIP codes than in non minority neighborhoods",
        "year": "2017",
        "domain": "Housing",
        "city": "Jefferson City",
        "state": "Missouri",
        "country": "USA",
        "lat": "38.579575",
        "lng": "-92.174782",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2017/04/05/your-money/minorities-car-insurance-rates.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Study Finds Car Insurers Raise Rates in Minority Neighborhoods",
        "issue": "Major insurers charged premiums that were 30% higher in minority ZIP codes than in non minority neighborhoods",
        "year": "2017",
        "domain": "Housing",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.266666",
        "lng": "-97.73333",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2017/04/05/your-money/minorities-car-insurance-rates.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "System no longer used, Houston teachers to pursue lawsuit over secret evaluation system",
        "issue": "An algorithm used to compute scores for student testing was used to determine which teachers to evaluate, fire and give bonus between 2011-2015. It is no longer used by the district",
        "year": "2017",
        "domain": "Education",
        "city": "Houston",
        "state": "Texas",
        "country": "USA",
        "lat": "29.749907",
        "lng": "-95.358421",
        "is_good": "Helpful",
        "link": "https://www.houstonchronicle.com/news/houston-texas/houston/article/Houston-teachers-to-pursue-lawsuit-over-secret-11139692.php",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "NVIDIA Uses AI to Slash Bandwidth on Video Calls",
        "issue": "NVIDIA replaced the traditional h.264 video codec with a neural network which reduced bandwidth on video calls drastically",
        "year": "2020",
        "domain": "Vision",
        "city": "Santa Clara",
        "state": "California",
        "country": "USA",
        "lat": "37.354107",
        "lng": "-121.955238",
        "is_good": "Helpful",
        "link": "https://petapixel.com/2020/10/06/nvidia-uses-ai-to-slash-bandwidth-on-video-calls/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "China's Social Credit Contagion Risk Ratings",
        "issue": "A new system that indicates quarantines shares personal data with the police",
        "year": "2020",
        "domain": "Health Services",
        "city": "",
        "state": "Beijing",
        "country": "China",
        "lat": "35.86166",
        "lng": "104.195396",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2020/03/01/business/china-coronavirus-surveillance.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Provema deploys face biometrics from iDenfy to facilitate loans and fight fraud",
        "issue": "To fight fraud, a customer must fill out an application form and undergo a biometric identity check. Provema also uses best data security practices",
        "year": "2020",
        "domain": "Business",
        "city": "",
        "state": "Katowice",
        "country": "Poland",
        "lat": "50.270908",
        "lng": "19.039993",
        "is_good": "Helpful",
        "link": "https://www.biometricupdate.com/202010/provema-deploys-face-biometrics-from-idenfy-to-facilitate-loans-and-fight-fraud",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Kantara and SAFE Identity to support each other\u2019s digital identity Trust Marks",
        "issue": "This collaboration simplifies digital identity assessment and Trust Mark processes",
        "year": "2020",
        "domain": "Business",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.889248",
        "lng": "-77.050636",
        "is_good": "Helpful",
        "link": "https://www.biometricupdate.com/202010/kantara-and-safe-identity-to-support-each-others-digital-identity-trust-marks",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Alexa can't understand what kids today are saying, either",
        "issue": "A research team at Clarkson analyzed speaker verification involving 30 children aged four to 14 and assessed that  voice verification apps are not appropriate for children unless more research is done. There are many concerns over privacy, consent and appearance of undue commercial profit collected from data from minors.",
        "year": "2020",
        "domain": "Business",
        "city": "Potsdam",
        "state": "New York",
        "country": "USA",
        "lat": "44.668659",
        "lng": "-74.984932",
        "is_good": "Harmful",
        "link": "https://www.biometricupdate.com/202010/alexa-cant-understand-what-kids-today-are-saying-either",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Amazon aims to improve biometric features and privacy with new edge AI chip in Echo devices",
        "issue": "A new processor enables a more natural experience for speech-based interaction. Scientific research was conducted on sound localization and computer vision to offer new features without creating data storage and privacy issues.",
        "year": "2020",
        "domain": "Business",
        "city": "Seattle",
        "state": "Washington",
        "country": "USA",
        "lat": "47.606209",
        "lng": "-122.332069",
        "is_good": "Helpful",
        "link": "https://www.biometricupdate.com/202010/amazon-aims-to-improve-biometric-features-and-privacy-with-new-edge-ai-chip-in-echo-devices",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "India\u2019s Tamil Nadu state adopts \u2018ethical\u2019 blockchain policy",
        "issue": "The policy advocates for transparency & audit, accountability & legal issues, misuse protection, digital divide, and data deficit, ethics and fairness and equity",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "Tamil Nadu",
        "country": "India",
        "lat": "11.127123",
        "lng": "78.656891",
        "is_good": "Helpful",
        "link": "https://www.newindianexpress.com/states/tamil-nadu/2020/sep/19/tamil-nadu-becomes-first-state-to-unveil-ethical-ai-blockchain-cybersecurity-policies-incountry-2199187.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Patient data protection key during COVID-19: Experts at IIHMR Univ webinar",
        "issue": "Advocating for data protection for the use of medical assistants during COVID-19",
        "year": "2020",
        "domain": "Health Services",
        "city": "Jaipur",
        "state": "Rajasthan",
        "country": "India",
        "lat": "26.92207",
        "lng": "75.778885",
        "is_good": "Helpful",
        "link": "https://www.expresshealthcare.in/news/patient-data-protection-key-during-covid-19-experts-at-iihmr-univ-webinar/424557/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Digital ID a surprising, qualified success for women in Afghanistan",
        "issue": "Afghanistan's national digital ID, the tazkira, is being updated as part of inclusion programs through the World Bank. This id hopes to replace paper documentation. Today, 47% of new e-tazkira digital identity registrants are women which helps getting documents for women easier. ",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "Kabul",
        "country": "Afghanistan",
        "lat": "34.543896",
        "lng": "69.160652",
        "is_good": "Helpful",
        "link": "https://www.biometricupdate.com/202010/digital-id-a-surprising-qualified-success-for-women-in-afghanistan",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Spotify: How A.I. is playing a bigger role in music streaming than you ever imagined",
        "issue": "Spotify machine learning head says AI helps with more nuanced tasks such as adding surprises to personalized playlists. Spotify also has human music editors that identify things we don't see in data such as new musical genres and trends.",
        "year": "2020",
        "domain": "Media",
        "city": "",
        "state": "Stockholm",
        "country": "Sweden",
        "lat": "59.334591",
        "lng": "18.06324",
        "is_good": "Helpful",
        "link": "https://fortune.com/2020/10/01/sonos-spotify-iheartradio-artificial-intelligence/?utm_source=A%7CI%3A+The+AI+Times&utm_campaign=1f519b4388-EMAIL_CAMPAIGN_AI_10_04_2020&utm_medium=email&utm_term=0_9ad0da4dbb-1f519b4388-210336307",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Sonos: How A.I. is playing a bigger role in music streaming than you ever imagined",
        "issue": "Sonos uses neural networks to create smooth transition between songs on the Sonos Radio",
        "year": "2020",
        "domain": "Media",
        "city": "Santa Barbara",
        "state": "California",
        "country": "USA",
        "lat": "34.42083",
        "lng": "-119.698189",
        "is_good": "Helpful",
        "link": "https://fortune.com/2020/10/01/sonos-spotify-iheartradio-artificial-intelligence/?utm_source=A%7CI%3A+The+AI+Times&utm_campaign=1f519b4388-EMAIL_CAMPAIGN_AI_10_04_2020&utm_medium=email&utm_term=0_9ad0da4dbb-1f519b4388-210336307",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "iHeartMedia: How A.I. is playing a bigger role in music streaming than you ever imagined",
        "issue": "iHeartMedia uses Super Hi-Fi, a company that uses neural networks and software to help prevent brief silence between songs.",
        "year": "2020",
        "domain": "Media",
        "city": "New York",
        "state": "New York",
        "country": "USA",
        "lat": "40.7128",
        "lng": "-74.006",
        "is_good": "Helpful",
        "link": "https://fortune.com/2020/10/01/sonos-spotify-iheartradio-artificial-intelligence/?utm_source=A%7CI%3A+The+AI+Times&utm_campaign=1f519b4388-EMAIL_CAMPAIGN_AI_10_04_2020&utm_medium=email&utm_term=0_9ad0da4dbb-1f519b4388-210336307",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "California Firefighters Tap AI for an Edge in Battling Wildfires",
        "issue": "AI-based tools help officials monitor fires, evacuate threatened areas and send often-stretched resources to places they will do most good.",
        "year": "2020",
        "domain": "Society",
        "city": "Calistoga",
        "state": "California",
        "country": "USA",
        "lat": "38.578796",
        "lng": "-122.579704",
        "is_good": "Helpful",
        "link": "https://www.wsj.com/articles/california-firefighters-tap-ai-for-an-edge-in-battling-wildfires-11601544600?utm_source=A%7CI%3A+The+AI+Times&utm_campaign=1f519b4388-EMAIL_CAMPAIGN_AI_10_04_2020&utm_medium=email&utm_term=0_9ad0da4dbb-1f519b4388-210336307",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook bans Holocaust denial content",
        "issue": "Facebook introduced a new policy that prohibits any content that denies or distorts the Holocaust,  this helps stop misinformation that AI algorithms can amplify.",
        "year": "2020",
        "domain": "Society",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Helpful",
        "link": "https://www.bbc.com/news/technology-54509975",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How Buenos Aires Can Fix Potholes In Hours",
        "issue": "Citizens in Argentina can use machine learning tools to report potholes",
        "year": "2018",
        "domain": "Society",
        "city": "Buenos Aires",
        "state": "",
        "country": "Argentina",
        "lat": "-34.603722",
        "lng": "-58.381592",
        "is_good": "Helpful",
        "link": "https://www.digitalistmag.com/improving-lives/2018/05/04/how-buenos-aires-can-fix-potholes-in-hours-06161400/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Mining Companies Using AI, Machine Learning And Robots",
        "issue": "EARTH AI helps mineral explorers find promising areas",
        "year": "2019",
        "domain": "Business",
        "city": "Santiago",
        "state": "",
        "country": "Chile",
        "lat": "-33.447487",
        "lng": "-70.673676",
        "is_good": "Helpful",
        "link": "https://blog.prototypr.io/mining-companies-using-ai-machine-learning-and-robots-e6dcdebaccc3",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "NotCo, a plant-based startup, uses AI to create plant-based foods",
        "issue": "By using this algorithm, the company is able to create plant-based food that smells, functions and tastes similar to animal based products",
        "year": "2019",
        "domain": "Business",
        "city": "Santiago",
        "state": "",
        "country": "Chile",
        "lat": "-33.447487",
        "lng": "-70.673676",
        "is_good": "Helpful",
        "link": "https://www.notco.com/index",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Machine learning is being used to uncover the mass graves of Mexico missing",
        "issue": "By using AI, people can identify missing people due to drug-related violence",
        "year": "2017",
        "domain": "Society",
        "city": "Veracruz",
        "state": "",
        "country": "Mexico",
        "lat": "19.173",
        "lng": "-96.134",
        "is_good": "Helpful",
        "link": "https://qz.com/958375/machine-learning-is-being-used-to-uncover-the-mass-graves-of-mexicos-missing/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Surveillance society: has technology at the US-Mexico border gone too far?",
        "issue": "The use of drones and AI-powered facial recognition cost civil liberties, privacy, and have a margin of error",
        "year": "2018",
        "domain": "Vision",
        "city": "Laredo",
        "state": "Texas",
        "country": "USA",
        "lat": "27.506",
        "lng": "-99.502",
        "is_good": "Harmful",
        "link": "https://www.theguardian.com/technology/2018/jun/13/mexico-us-border-wall-surveillance-artificial-intelligence-technology",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Feelenials uses AI to understand employee emotions",
        "issue": "Feelenials uses analytics to track and improve moods that drive your company. This could lead to an invasion of privacy and labor issues",
        "year": "2020",
        "domain": "Society",
        "city": "Bogota",
        "state": "",
        "country": "Colombia",
        "lat": "4.62",
        "lng": "-74.06",
        "is_good": "Harmful",
        "link": "https://contxto.com/en/colombia/feelenials-ai-employee-emotions/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A deepfake bot is being used to \u201cundress\u201d underage girls",
        "issue": "This bot leads to the gamification of harassment and is targeted at young girls. ",
        "year": "2020",
        "domain": "Vision",
        "city": "Dubai",
        "state": "",
        "country": "United Arab Emirates",
        "lat": "25.276",
        "lng": "55.29",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2020/10/20/1010789/ai-deepfake-bot-undresses-women-and-underage-girls/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Deepfake Revenge Porn",
        "issue": "Deepfake technology is used to create image-based sexual abuse by distributing fake nude images with non-consensual creation",
        "year": "2019",
        "domain": "Vision",
        "city": "Canberra",
        "state": "",
        "country": "Australia",
        "lat": "-35.080937",
        "lng": "149.030005",
        "is_good": "Harmful",
        "link": "https://www.aic.gov.au/sites/default/files/2020-05/imagebased_sexual_abuse_victims_and_perpetrators.pdf",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Using AI to Detect Seemingly Perfect Deep-Fake Videos",
        "issue": "This program accurately spots more than 80 percent of fakes by recognizing minute mismatches between the sounds people make and the shapes of their mouths.",
        "year": "2020",
        "domain": "Vision",
        "city": "Palo Alto",
        "state": "California",
        "country": "USA",
        "lat": "37.441883",
        "lng": "-122.143021",
        "is_good": "Helpful",
        "link": "https://hai.stanford.edu/blog/using-ai-detect-seemingly-perfect-deep-fake-videos?utm_source=linkedin&utm_medium=social&utm_content=Stanford%20HAI_linkedin_HAI_202010191231_sf130752592&utm_campaign=&sf130752592=1",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Austin AI Housing Analysis",
        "issue": "Develops a value-driven AI system that can evaluate historical housing development and help policymakers shape equitable, inclusive and sustainable plans and regulation",
        "year": "2020",
        "domain": "Housing",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.267153",
        "lng": "-97.07",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/austin-housing-analysis/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Cameras, AI and Public Values in Smart Cities",
        "issue": "This research project looks at various implications of public-sector deployed cameras.",
        "year": "2020",
        "domain": "Vision",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.267153",
        "lng": "-97.743057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/cameras-ai-and-public-values-in-smart-cities/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Countering Misinformation and Disinformation",
        "issue": "This research project discusses how did- and misinformation operate in social media environments and how platform regulations or AI might be tailored to counter these problems",
        "year": "2020",
        "domain": "Media",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.967153",
        "lng": "-97.943057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/countering-misinformation-and-disinformation/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Design of Fair AI Systems via Human-Centric Detection",
        "issue": "This research project helps identify bias in datasets and fairness of alternative algorithms and evaluation metrics",
        "year": "2020",
        "domain": "Society",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.57153",
        "lng": "-97.543057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/design-of-fair-ai-systems-via-human-centric-detection/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Disinformation in Context: AI, Platforms and Policy",
        "issue": "An analysis of Facebook ads released by US Congress in 2018, message circulation, and sampled twitter data linked to Russian accounts",
        "year": "2020",
        "domain": "Media",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.267153",
        "lng": "-97.743057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/disinformation-in-context-ai-platforms-and-policy/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How African-American and Latinx Youth Evaluate Their Experiences with Digital Assistants",
        "issue": "This research project analyzes how social and economic disparities and cultural differences shape children's engagement with AI",
        "year": "2020",
        "domain": "Society",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30",
        "lng": "-97",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/how-african-american-and-latinx-youth-evaluate-their-experiences-with-digital-assistants/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Inspection of City Infrastructure Via Peripheral Perception",
        "issue": "This research analyzes to see how the City of Austin can leverage vehicles with computer vision and robotics to automate infrastructure inspection",
        "year": "2020",
        "domain": "Housing",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.267153",
        "lng": "-97.743057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/inspection-of-city-infrastructure-via-peripheral-perception/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Optimize EMS Responses During Extreme Events",
        "issue": "This research project looks at creating an optimal EMS routing strategy using real-time information that is adaptable to changing situations and robust to disruptions",
        "year": "2020",
        "domain": "Health Services",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.127153",
        "lng": "-97.343057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/optimize-ems-responses-during-extreme-events/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Postpartum Support-Bot: An Ethically Co-Designed Chatbot for Mothers Experiencing or at Risk of Postpartum Mood and Anxiety Disorders",
        "issue": "This chatbot application provides meaningful support to mothers struggling with or at risk of PPMADs",
        "year": "2020",
        "domain": "Health Services",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.17153",
        "lng": "-97.443057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/postpartum-support-bot-an-ethically-co-designed-chatbot-for-mothers-experiencing-or-at-risk-of-postpartum-mood-and-anxiety-disorders/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Privacy Preferences and Values for Computer Vision",
        "issue": "This research explores the conflict between convenience and privacy in computer vision",
        "year": "2020",
        "domain": "Society",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.067153",
        "lng": "-97.743057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/privacy-preferences-and-values-for-computer-vision/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Smart Cities Should Be Good Cities: AI, Equity, and Homelessness",
        "issue": "This research explores how to use AI to empower individuals experiencing homelessness and assist agencies",
        "year": "2020",
        "domain": "Housing",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.267153",
        "lng": "-97.03057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/smart-cities-should-be-good-cities-ai-equity-and-homelessness/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Urban Health Risk Mapping",
        "issue": "An AI system that measures the health effects of neighborhood environments in Houston",
        "year": "2020",
        "domain": "Health Services",
        "city": "Houston",
        "state": "Texas",
        "country": "USA",
        "lat": "29.749907",
        "lng": "-95.743057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/urban-health-risk-mapping/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Urban Health Risk Mapping",
        "issue": "An AI system that measures the health effects of neighborhood environments in Dallas",
        "year": "2020",
        "domain": "Health Services",
        "city": "Dallas ",
        "state": "Texas",
        "country": "USA",
        "lat": "32.779167",
        "lng": "-96.743057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/urban-health-risk-mapping/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Urban Health Risk Mapping",
        "issue": "An AI system that measures the health effects of neighborhood environments in San Antonio",
        "year": "2020",
        "domain": "Health Services",
        "city": "San Antonio",
        "state": "Texas",
        "country": "USA",
        "lat": "29.424349",
        "lng": "-98.491142",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/urban-health-risk-mapping/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Urban Health Risk Mapping",
        "issue": "An AI system that measures the health effects of neighborhood environments in Austin",
        "year": "2020",
        "domain": "Health Services",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.067153",
        "lng": "-97.743057",
        "is_good": "Helpful",
        "link": "https://events.goodsystems.utexas.edu/projects/urban-health-risk-mapping/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Live facial recognition is tracking kids suspected of being criminals",
        "issue": "The system has since led to numerous false arrests (links in Spanish), which the police have no established protocol for handling. One man who was mistakenly identified was detained for six days and about to be transferred to a maximum security prison before he finally cleared up his identity. Another was told he should expect to be repeatedly flagged in the future even though he\u2019d proved he wasn't who the police were looking for.",
        "year": "2020",
        "domain": "Vision",
        "city": "Buenos Aires",
        "state": "",
        "country": "Argentina",
        "lat": "-34.03722",
        "lng": "-58.081592",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2020/10/09/1009992/live-facial-recognition-is-tracking-kids-suspected-of-crime/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Artificial intelligence model detects asymptomatic Covid-19 infections through cellphone-recorded cough",
        "issue": "Although asymptomatic people might not show symptoms, the model is able to distinguish coughs from healthy individuals and those with COVID-19 with a 98.5% accuracy",
        "year": "2020",
        "domain": "Health Services",
        "city": "Cambridge",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.373611",
        "lng": "-71.110558",
        "is_good": "Helpful",
        "link": "https://news.mit.edu/2020/covid-19-cough-cellphone-detection-1029",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI has just revealed there are over 1.8 billion trees in the Sahara Desert",
        "issue": "The researchers used detailed satellite imagery from NASA with deep learning. Without deep learning, these trees would have been invisible",
        "year": "2020",
        "domain": "Society",
        "city": "Sahara Desert",
        "state": "",
        "country": "Egypt",
        "lat": "23.806078",
        "lng": "11.288452",
        "is_good": "Helpful",
        "link": "https://www.weforum.org/agenda/2020/10/ai-billion-trees-sahara-desert-technology/#:~:text=Researchers%20counted%20over%201.8%20billion,humid%20zones%20of%20West%20Africa.",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How a fake persona laid the groundwork for a Hunter Biden conspiracy deluge",
        "issue": "A fake identity, Martin Aspen, was created by artificial intelligence to defame Hunter Biden",
        "year": "2020",
        "domain": "Vision",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.889248",
        "lng": "-77.050636",
        "is_good": "Harmful",
        "link": "https://www.nbcnews.com/tech/security/how-fake-persona-laid-groundwork-hunter-biden-conspiracy-deluge-n1245387",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How an Algorithm Blocked Kidney Transplants to Black Patients",
        "issue": "This kidney algorithm explicitly takes account of race whose Black patients' scores would have qualified them for a kidney transplant waitlist, yet none of them were referred or evaluated for a transplant.",
        "year": "2020",
        "domain": "Health Services",
        "city": "Boston",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.362293",
        "lng": "-71.059893",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/how-algorithm-blocked-kidney-transplants-black-patients/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Surveillance Startup Used Own Cameras to Harass Coworkers",
        "issue": "Employees at Verkada accessed the company's facial recognition system to take photos of women colleagues and make sexually explicit jokes.",
        "year": "2020",
        "domain": "Vision",
        "city": "San Mateo",
        "state": "California",
        "country": "USA",
        "lat": "37.554169",
        "lng": "-122.313057",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en/article/pkdyqm/surveillance-startup-used-own-cameras-to-harass-coworkers",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "The Netherlands Is Becoming a Predictive Policing Hot Spot",
        "issue": "CAS, the Crime Anticipation System, perpetuates discriminatory practices by looking at variables such as number of one parent households, number of social benefits recipients, and number of non-Western immigrants",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Amsterdam",
        "state": "",
        "country": "Netherlands",
        "lat": "52.377956",
        "lng": "4.89707",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en/article/5dpmdd/the-netherlands-is-becoming-a-predictive-policing-hot-spot",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI has cracked a key mathematical puzzle for understanding our world",
        "issue": "A new deep-learning technique is utilized to solve for PDEs",
        "year": "2020",
        "domain": "Education",
        "city": "Pasadena",
        "state": "California",
        "country": "USA",
        "lat": "34.156",
        "lng": "-118.13",
        "is_good": "Helpful",
        "link": "https://www.technologyreview.com/2020/10/30/1011435/ai-fourier-neural-network-cracks-navier-stokes-and-partial-differential-equations/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI camera operator repeatedly confuses bald head for soccer ball during live stream",
        "issue": "During a live stream, the AI camera operator repeatedly confused a lineman's bald head for a soccer ball",
        "year": "2020",
        "domain": "Vision",
        "city": "Inverness",
        "state": "",
        "country": "UK",
        "lat": "57.47772",
        "lng": "-4.224721",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/tldr/2020/11/3/21547392/ai-camera-operator-football-bald-head-soccer-mistakes",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "ICE, IRS Explored Using Hacking Tools, New Documents Show",
        "issue": "ICE agents obtained a warrant to remotely access an electronic device as part of a criminal investigation/case",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Washington DC",
        "state": "",
        "country": "USA",
        "lat": "38.889248",
        "lng": "-77.050636",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en/article/epd4wp/ice-irs-hacking-tools",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "LAPD gets approval to begin recording, storing aerial footage of protests",
        "issue": "This approval limits protestor's rights and includes the usage of facial recognition",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Los Angeles",
        "state": "California",
        "country": "USA",
        "lat": "34.052235",
        "lng": "-118.243683",
        "is_good": "Harmful",
        "link": "https://www.latimes.com/california/story/2020-10-27/lapd-to-begin-recording-aerial-footage-of-protests",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How Police Can Crack Locked Phones- And Extract Information",
        "issue": "Police have maintained access to suspects\u2019 phones even as these defenses grow more complex: by contracting with digital forensic firms that specialize in bypassing locks and accessing and copying encrypted data.",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Coon Rapids",
        "state": "Minnesota",
        "country": "USA",
        "lat": "45.223258",
        "lng": "-93.541036",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/how-police-crack-locked-phones-extract-information/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Split-Second \u2018Phantom\u2019 Images Can Fool Tesla\u2019s Autopilot",
        "issue": "Research at The University of Negev showed that semi-autonomous vehicles can be compromised by flashing phantom images",
        "year": "2020",
        "domain": "Vision",
        "city": "Beersheba",
        "state": "",
        "country": "",
        "lat": "31.25181",
        "lng": "34.7913",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/tesla-model-x-autopilot-phantom-images/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How to make a chatbot that isn't racist or sexist",
        "issue": "A practical guide on how to build responsible chatbots",
        "year": "2020",
        "domain": "Business",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "51.507351",
        "lng": "-0.127758",
        "is_good": "Helpful",
        "link": "https://www.technologyreview.com/2020/10/23/1011116/chatbot-gpt3-openai-facebook-google-safety-fix-racist-sexist-language-ai/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook Charged Biden a Higher Price than Trump for Campaign Ads",
        "issue": "In swing states, Biden paid average ad rates of $34 compared with Trump\u2019s average of $17 in July and August. Differential pricing for political advertising is illegal. Federal laws require TV stations to charge candidates the same price.",
        "year": "2020",
        "domain": "Society",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Harmful",
        "link": "https://themarkup.org/election-2020/2020/10/29/facebook-political-ad-targeting-algorithm-prices-trump-biden",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Automatic Signature Verification Software Threatens to Disenfranchise U.S. voters",
        "issue": "Algorithm used is on single-language (English) handwriting. Certain voters such as those with mental or physical disabilities, stress-related ailments, or for those who don't write in English are potentially at higher risk of having their ballot rejected",
        "year": "2020",
        "domain": "Society",
        "city": "Montgomery",
        "state": "Alabama",
        "country": "USA",
        "lat": "32.361668",
        "lng": "-86.279167",
        "is_good": "Harmful",
        "link": "https://venturebeat.com/2020/10/25/automatic-signature-verification-software-threatens-to-disenfranchise-u-s-voters/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Apple will require apps to add privacy \u2018nutrition labels\u2019 starting December 8th",
        "issue": "These labels list what the information an app collects, data that is used to track a user and data that is linked to a user",
        "year": "2020",
        "domain": "Business",
        "city": "Cupertino",
        "state": "California",
        "country": "USA",
        "lat": "37.323",
        "lng": "-122.0322",
        "is_good": "Helpful",
        "link": "https://www.theverge.com/2020/11/5/21551926/apple-privacy-developers-nutrition-labels-app-store-ios-14",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "California\u2019s Prop 25 would replace cash bail with algorithms, but questions around fairness and transparency",
        "issue": "Some view that these algorithms are an impartial option that removes financial burden of cash bail which will disproportionately impacts poor people.Others say that the risk assessment approaches eliminates bail money completely which could correct social, economic and financial inequalities.",
        "year": "2020",
        "domain": "Society",
        "city": "Sacramento",
        "state": "California",
        "country": "USA",
        "lat": "38.5816",
        "lng": "-121.4944",
        "is_good": "Harmful",
        "link": "https://venturebeat.com/2020/11/03/the-unavoidable-glaring-cproblem-with-californias-prop-25-to-replace-cash-bail-with-an-algorithm/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Dahua Racist Uyghur Tracking Revealed",
        "issue": "Dahua had functions to detect Uyghurs in vision product",
        "year": "2020",
        "domain": "Vision",
        "city": "Xinjiang",
        "state": "",
        "country": "China",
        "lat": "44.402",
        "lng": "86.154",
        "is_good": "Harmful",
        "link": "https://ipvm.com/reports/dahua-uyghur",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "CNN Pornhub Deepfake",
        "issue": "A fake notification was deemed as the \"highlight of the election\"",
        "year": "2020",
        "domain": "Vision",
        "city": "Atlanta",
        "state": "Georgia",
        "country": "USA",
        "lat": "33.75",
        "lng": "-84.83",
        "is_good": "Harmful",
        "link": "https://www.nzherald.co.nz/world/inside-truth-behind-cnns-pornhub-viral-us-election-video/WBEBJIMEJ53ZTUGNMZJRTH46BA/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Frankenstein's Monster:' Images of Sexual Abuse Are Fueling Algorithmic Porn",
        "issue": "Czech Casting and Girls do Porn create machine-learning generated porn who find the creations uniquely dehumanizing ",
        "year": "2020",
        "domain": "Vision",
        "city": "Prague",
        "state": "",
        "country": "Czech Republic",
        "lat": "50.07",
        "lng": "14.42",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en/article/akdgnp/sexual-abuse-fueling-ai-porn-deepfake-czech-casting-girls-do-porn",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "VPN Providers Say China Blocks Encryption Using Machine Learning Algorithms",
        "issue": "Censorship system can learn, discover and block encrypted VPN protocols by machine learning in protocol classification",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "Beijing",
        "country": "China",
        "lat": "35.86166",
        "lng": "104.05396",
        "is_good": "Harmful",
        "link": "https://yro.slashdot.org/story/12/12/20/1456251/vpn-providers-say-china-blocks-encryption-using-machine-learning-algorithms",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "An SVM-based machine learning method for accurate internet traffic classification",
        "issue": "A machine learning method based on SVM is proposed for accurate Internet classification which is critical in network security monitoring and traffic engineering",
        "year": "2008",
        "domain": "Business",
        "city": "",
        "state": "Beijing",
        "country": "China",
        "lat": "35.0166",
        "lng": "104.195396",
        "is_good": "Helpful",
        "link": "https://link.springer.com/article/10.1007/s10796-008-9131-2",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How the U.S Military Buys Location Data from Ordinary Apps",
        "issue": "A Muslim prayer app with over 98 million downloads is one of the apps connected to a wide-ranging supply chain that sends ordinary people's personal data to brokers, contractors, and the military.",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.889248",
        "lng": "-77.050636",
        "is_good": "Harmful",
        "link": "https://www.vice.com/amp/en/article/jgqm5x/us-military-location-data-xmode-locate-x",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "New Orleans Police Department using facial recognition despite years of denial",
        "issue": "The New Orleans Police Department has confirmed that it is utilizing facial recognition for its investigations, despite years of assurances that the city wasn\u2019t employing the technology.",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "New Orleans",
        "state": "Louisiana",
        "country": "USA",
        "lat": "29.951",
        "lng": "-90.07",
        "is_good": "Harmful",
        "link": "https://thelensnola.org/2020/11/12/new-orleans-police-department-using-facial-recognition-despite-years-of-denial/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "The iPhone X can't tell the difference between identical twins",
        "issue": "When Apple released the Face ID, it warned about a 1-in 1 million false acceptance rate might be lower if two people shared similar DNA. Therefore, siblings or identical twins were confused by Face ID",
        "year": "2017",
        "domain": "Vision",
        "city": "Cupertino",
        "state": "California ",
        "country": "USA",
        "lat": "37.323",
        "lng": "-122.0322",
        "is_good": "Harmful",
        "link": "https://mashable.com/2017/10/31/putting-iphone-x-face-id-to-twin-test/#ZSbrCeQ60qqf",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "LAPD warns that navigation apps are steering people to neighborhoods on fire",
        "issue": "The problem? Those apps look for roads without many cars on them, and try to route you there. Which is great when you're trying to avoid run-of-the-mill traffic. But not when the roads are clear because of nearby fires.",
        "year": "2017",
        "domain": "Society",
        "city": "Los Angeles",
        "state": "California",
        "country": "USA",
        "lat": "34.052235",
        "lng": "-118.043683",
        "is_good": "Harmful",
        "link": "https://mashable.com/2017/12/07/lapd-warns-that-navigation-apps-are-steering-people-to-neighborhoods-on-fire/#8Eg.3vjAVPqK",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google's AI has some seriously messed up opinions about homosexuality",
        "issue": "The sentiment analysis system returned negative ratings for \"I'm a homosexual\" and \"I'm queer\" while \"I'm straight\" returned positive ratings",
        "year": "2017",
        "domain": "Society",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.006051",
        "lng": "-122.003855",
        "is_good": "Harmful",
        "link": "https://mashable.com/2017/10/25/google-machine-learning-bias/#7AyGipoEHmqF",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Face ID has been defeated again, and this time it was 'simple' from Bkav",
        "issue": "Face ID was tricked with constructed mask",
        "year": "2017",
        "domain": "Vision",
        "city": "Hanoi",
        "state": "",
        "country": "Vietnam",
        "lat": "21.028",
        "lng": "105.8",
        "is_good": "Harmful",
        "link": "https://mashable.com/2017/11/27/apple-face-id-mask-defeated-again/#N3QOEjviHiqW",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How Elon Musk is fixing Tesla Model 3 production problems: More humans",
        "issue": "After realizing that robots were the core reason why Tesla had delays, Musk hired more humans to assembly lines",
        "year": "2018",
        "domain": "Business",
        "city": "Palo Alto",
        "state": "California",
        "country": "USA",
        "lat": "37.01883",
        "lng": "-122.043021",
        "is_good": "Helpful",
        "link": "https://mashable.com/2018/04/14/elon-musk-fixes-model-3-production-problems-more-humans/#PqJYXwFyWPq7",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Why Oakland Police Turned Down Predictive Policing",
        "issue": "Due to understanding the unintended consequences of predictive policing such as disproportionately scrutinizing certain neighborhoods and eroding community trust, Oakland Police did not implement this system",
        "year": "2016",
        "domain": "Law Enforcement",
        "city": "Oakland",
        "state": "California",
        "country": "USA",
        "lat": "37.004363",
        "lng": "-122.071111",
        "is_good": "Helpful",
        "link": "https://www.vice.com/en/article/ezp8zp/minority-retort-why-oakland-police-turned-down-predictive-policing",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Tesla Autopilot gets tricked into accelerating from 35 to 85 mph with modified speed limit sign",
        "issue": "A group of hackers has managed to trick Tesla\u2019s first-generation Autopilot into accelerating from 35 to 85 mph with a modified speed limit sign that humans would be able to read correctly.",
        "year": "2020",
        "domain": "Business",
        "city": "Palo Alto",
        "state": "California",
        "country": "USA",
        "lat": "37.01883",
        "lng": "-122.043021",
        "is_good": "Harmful",
        "link": "https://electrek.co/2020/02/19/tesla-autopilot-tricked-accelerate-speed-limit-sign/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Disney is using AI to correct gender bias in its movies",
        "issue": "Using AI, Davis\u2019 company created the gender-bias tool called that analyzes scripts to pick up any gender bias, it counts the number of male and female characters and then evaluates whether the breakdown is representative of the actual population.",
        "year": "2019",
        "domain": "Media",
        "city": "Los Angeles",
        "state": "California",
        "country": "USA",
        "lat": "34.052235",
        "lng": "-118.243683",
        "is_good": "Helpful",
        "link": "https://thenextweb.com/tech/2019/10/09/disney-is-using-ai-to-correct-gender-bias-in-its-movies/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "This AI Tool Corrects Gender Bias In Portrayal Of Females In Movies",
        "issue": "Researchers at The Allen Institute for AI in collaboration with The University of Washington created an AI-based tool that rewrites text to correct potential gender bias in character portrayals",
        "year": "2020",
        "domain": "Media",
        "city": "Seattle ",
        "state": "Washington",
        "country": "USA",
        "lat": "47.606209",
        "lng": "-122.332069",
        "is_good": "Helpful",
        "link": "https://analyticsindiamag.com/this-ai-tool-corrects-gender-bias-in-portrayal-of-females-in-movies/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Artificial Intelligence: The AI-Powered Nudemeter For Dark-Skinned Women",
        "issue": "Lui had to train her algorithm with images that more accurately represented the skin-colour spectrum, from the palest whites to the darkest browns. To this end, she issued call outs encouraging volunteers of all skin tones to submit photos of themselves to aid in her mission to \u201cchange the standard of beauty to match the full range of diversity in human skin.\u201d",
        "year": "2020",
        "domain": "Business",
        "city": "Cambridge",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.373611",
        "lng": "-71.110558",
        "is_good": "Helpful",
        "link": "https://www.wired.co.uk/article/atima-lui-nudemeter-beauty-industry",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A.I. Tool Provides More Accurate Flu Forecasts",
        "issue": "The A.I. system is able to outperform other state-of-the-art forecasting methods, delivering up to an 11% increase in accuracy and predicting influenza outbreaks up to 15 weeks in advance.",
        "year": "2020",
        "domain": "Health Services",
        "city": "Hoboken",
        "state": "New Jersey",
        "country": "USA",
        "lat": "40.7426017",
        "lng": "-74.0284736",
        "is_good": "Helpful",
        "link": "https://www.stevens.edu/news/ai-tool-provides-more-accurate-flu-forecasts-0#:~:text=%2Dpowered%20forecasting%20tool%20developed%20by,to%2015%20weeks%20in%20advance.",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Humans in the Loop Help Refugees Find Work",
        "issue": "Hundreds of refugees in the Balkans and Middle East help with AI through startup",
        "year": "2020",
        "domain": "Labor",
        "city": "Sofia",
        "state": "",
        "country": "Bulgaria",
        "lat": "42.698334",
        "lng": "23.319941",
        "is_good": "Helpful",
        "link": "https://today.rtl.lu/news/business-and-tech/a/1468002.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google AI Model Cards",
        "issue": "Cards help explain machine-learning models and their shortcomings",
        "year": "2020",
        "domain": "Business",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Helpful",
        "link": "https://modelcards.withgoogle.com/about",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A Simple Tactic That Could Help Reduce Bias in AI",
        "issue": "The blind taste test\u201d could work like this: train the model on all data, including referral calls from the community. Then re-train the model on all the data except that one. If the model\u2019s predictions are equally good without referral-call information, it means the model makes predictions that are blind to that factor. But if the predictions are different when those calls are included, it indicates that either the calls represent a valid explanatory variable in the model, or there may be potential bias in the data (as has been argued for the AFST) that should be examined further before relying on the algorithm.",
        "year": "2020",
        "domain": "Society",
        "city": "Cambridge",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.373611",
        "lng": "-71.110558",
        "is_good": "Helpful",
        "link": "https://hbr.org/2020/11/a-simple-tactic-that-could-help-reduce-bias-in-ai",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A Child Abuse Prediction Model Fails Poor Families",
        "issue": "Human choices, biases, and discretion are built into the system in several ways. First, the AFST does not actually model child abuse or neglect. The number of child maltreatment\u2013related fatalities and near fatalities in Allegheny County is thankfully very low. Because this means data on the actual abuse of children is too limited to produce a viable model, the AFST uses proxy variables to stand in for child maltreatment. One of the proxies is community re-referral, when a call to the hotline about a child was initially screened out but CYF receives another call on the same child within two years. The second proxy is child placement, when a call to the hotline about a child is screened in and results in the child being placed in foster care within two years. So, the AFST actually models decisions made by the community (which families will be reported to the hotline) and by CYF and the family courts (which children will be removed from their families), not which children will be harmed.",
        "year": "2020",
        "domain": "Society",
        "city": "Pittsburgh",
        "state": "Pennsylvania",
        "country": "USA",
        "lat": "40.440624",
        "lng": "-79.995888",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/excerpt-from-automating-inequality/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google creating AI system to help blind and vision-impaired people run races",
        "issue": "Project Guideline is a Google-sponsored research project that utilizes a mobile application and audio cues to guide runners with visual impairments. Computer vision is used to identify pre-painted lines and identify diversions from it. ",
        "year": "2020",
        "domain": "Health Services",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Helpful",
        "link": "https://blog.google/outreach-initiatives/accessibility/project-guideline/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Texas launches tool that uses AI to help students with financial aid",
        "issue": "The Texas Higher Education Coordinating Board has launched ADVi, a chatbot that uses AI to answer questions about FAFSA and financial aid while also providing relevant resources. ",
        "year": "2020",
        "domain": "Education",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.267153",
        "lng": "-97.743057",
        "is_good": "Helpful",
        "link": "https://www.houstonchronicle.com/local/education/campus-chronicles/article/Texas-launches-AI-platform-to-assist-15770561.php",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "In Tech-Savvy China, Cash-Carrying Elderly Are Being Left Behind",
        "issue": "A bank apologized Saturday after a 94-year-old woman had to be lifted up by her son so she could use a facial-recognition system to active her social security card. And on Monday, another elderly woman was rejected when she tried to use cash to pay for her medical insurance. An employee explained that she could pay on her phone or call a relative if she needed help.",
        "year": "2020",
        "domain": "Vision",
        "city": "",
        "state": "Beijing",
        "country": "China",
        "lat": "35.86166",
        "lng": "104.195396",
        "is_good": "Harmful",
        "link": "http://www.sixthtone.com/news/1006499/in-tech-savvy-china%2C-cash-carrying-elderly-are-being-left-behind",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Imperial engineer to develop AI clinician to help frontline health workers",
        "issue": "He aims to develop an \u2018AI clinician\u2019 colleague to aid doctors and clinicians and relieve pressure on the NHS. The system could also be used in sectors such as aerospace or energy, where accurate decision-making under high-pressure conditions is crucial.",
        "year": "2020",
        "domain": "Health Services",
        "city": "",
        "state": "London",
        "country": "England",
        "lat": "51.507351",
        "lng": "-0.127758",
        "is_good": "Helpful",
        "link": "https://www.imperial.ac.uk/news/209593/imperial-engineer-develop-ai-clinician-help/?utm_source=linkedin&utm_medium=social&utm_content=fa5fddea-dd59-4295-b596-6d14e564a96a&utm_campaign=news",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Uganda is using Huawei\u2019s facial recognition tech to crack down on dissent after anti-government protests",
        "issue": "In the absence of any judicial oversight, there are also concerns of backdoor access to the system for illegal facial recognition surveillance on potential targets and stifling of anti-regime comments and any peaceful civil action. Local rights group, Unwanted Witness, has previously called for the observance of international human rights law in the implementation of the project to safeguard human rights, freedoms, and democracy in the country.",
        "year": "2020",
        "domain": "Vision",
        "city": "",
        "state": "Kampala",
        "country": "Uganda",
        "lat": "0.347596",
        "lng": "32.58252",
        "is_good": "Harmful",
        "link": "https://qz.com/africa/1938976/uganda-uses-chinas-huawei-facial-recognition-to-snare-protesters/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Youtube Updates Terms of Service to not collect faces",
        "issue": "What changed: We added \u201cor faces\u201d to our Terms of Service to be explicit about what can\u2019t be collected.\nWhat this means: We are adding this into our Terms of Service to be extremely explicit about what kind of data users cannot collect from YouTube. YouTube has never allowed the collection of personally identifiable information (including data that can be used for facial recognition) under previous versions of our Terms of Service, but we want to specifically include language around facial data to be even more clear. We take user privacy seriously and want you to feel confident that your data is never being misused. \n",
        "year": "2020",
        "domain": "Media",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Helpful",
        "link": "https://support.google.com/youtube/thread/83733719?hl=en",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Using artificial intelligence can improve pregnant women's health",
        "issue": "\nThe analysis confirmed that disorders such as congenital heart birth defects or macrosomia, gestational diabetes and preterm birth can be detected earlier when artificial intelligence is used. In the latter case, studies into cases involving artificial intelligence found a correlation between the number of pre-term births and the environmental pollution to which the pregnant women had been previously exposed.",
        "year": "2020",
        "domain": "Health Services",
        "city": "",
        "state": "Seville",
        "country": "Spain",
        "lat": "37.392529",
        "lng": "-5.994072",
        "is_good": "Helpful",
        "link": "https://www.medica-tradefair.com/en/News/News_from_the_Editors/Using_artificial_intelligence_can_improve_pregnant_women_s_health",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Serenata de Amor",
        "issue": "A artificial intelligence that analyzes Brazilian congresspeople expenses while they are in office. Rosie can find suspicious spendings and engage citizens in the discussion about these findings.",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "",
        "country": "Brazil",
        "lat": "-15.793889",
        "lng": "-47.882778",
        "is_good": "Helpful",
        "link": "https://serenata.ai/en/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "The moon may have far more lunar craters than previously known",
        "issue": "Using AI, scientists have estimated that there are more than 100,000 craters on the moon. That's far more than the 9,137 officially recognized by the International Astronomical Union and largely detected manually using elevation information and images, the study said.",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "Changchun",
        "country": "China",
        "lat": "43.891262",
        "lng": "125.331345",
        "is_good": "Helpful",
        "link": "https://www.cnn.com/2020/12/22/world/moon-craters-study-scn/index.html?utm_content=2020-12-27T11%3A01%3A07&utm_source=twCNN&utm_term=link&utm_medium=social",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Uni revealed it killed off its PhD-applicant screening AI \u2013 just as its inventors gave a lecture about the tech",
        "issue": "The GRADE algorithm was developed by a pair of academics at the University of Texas at Austin, and it was used from 2013 to this year to assess those applying for a PhD at the US college's respected computer-science department. The software was trained using the details of previously accepted students, the idea being to teach the system to identify people the school would favor, and to highlight them to staff who would make the final call on the applications. It's likely the program picked up biases against applicants of certain backgrounds excluded from that historical data.",
        "year": "2020",
        "domain": "Education",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.067153",
        "lng": "-97",
        "is_good": "Helpful",
        "link": "https://www.theregister.com/2020/12/08/texas_compsci_phd_ai/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "As China Tracked Muslims, Alibaba Showed Customers How They Could, Too",
        "issue": "Alibaba marketed its ability to detect Uighur faces, too. Last week we highlighted how Huawei tested face recognition systems that could trigger a \u201cUighur alarm.\u201d Here, API documentation for Alibaba and Kingsoft Cloud \u2014 both publicly traded companies \u2014 \u201cshowed how clients could use [their] software to detect the faces of Uighurs and other ethnic minorities within images and videos.\u201d",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "Hangzhou",
        "country": "China",
        "lat": "30.274084",
        "lng": "120.155067",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2020/12/16/technology/alibaba-china-facial-recognition-uighurs.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How our data encodes systematic racism",
        "issue": "People of color continue to be under-represented in datasets despite making up the majority of the population, language-learning bots are trained on media rife with white supremacist ideology, and racist criminal justice data is seen as ground truth.",
        "year": "2020",
        "domain": "Society",
        "city": "Cambridge",
        "state": "Massachusetts",
        "country": "USA",
        "lat": "42.373611",
        "lng": "-71.110558",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2020/12/10/1013617/racism-data-science-artificial-intelligence-ai-opinion/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Huawei tested AI software that could recognize Uighur minorities and alert police, report says",
        "issue": "An internal report claims the face-scanning system could trigger a \u2018Uighur alarm,\u2019 sparking concerns that the software could help fuel China\u2019s crackdown on the mostly Muslim minority group",
        "year": "2020",
        "domain": "Vision",
        "city": "",
        "state": "Hangzhou",
        "country": "China",
        "lat": "30.074084",
        "lng": "120.055067",
        "is_good": "Harmful",
        "link": "https://www.washingtonpost.com/technology/2020/12/08/huawei-tested-ai-software-that-could-recognize-uighur-minorities-alert-police-report-says/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "We read the paper that forced Timnit Gebru out of Google. Here\u2019s what it says.",
        "issue": "Timnit Gebru says she was fired from Google over a paper highlighting the opportunity costs of natural language processing. With seven coauthors, the renowned AI ethicist highlighted the environmental toll of training large models, as well as their ability to manipulate language to the benefit of large corporations. Google allegedly refused to approve the paper for publication without discussion or revision -UpTurn Newsletter",
        "year": "2020",
        "domain": "Society",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2020/12/04/1013294/google-ai-ethics-research-paper-forced-out-timnit-gebru/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Police Drones are starting to think for themselves",
        "issue": "These drones mirror the technology available to self-driving cars and can \u201ctrack vehicles and people automatically.\u201d Jay Stanley from the ACLU warns that these drones \u201ccan be used to investigate known crimes. But they are also sensors that can generate offenses.\u201d",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Chula Vista",
        "state": "California",
        "country": "USA",
        "lat": "32.639954",
        "lng": "-117.106705",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2020/12/05/technology/police-drones.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Report \u2013 Algorithm-driven Hiring Tools: Innovative Recruitment or Expedited Disability Discrimination?",
        "issue": "A new report from the Center for Democracy & Technology highlights how hiring technologies may screen out people with disabilities and fall far short of ADA standards. The report provides many actionable steps, such as not screening out people based on subjective personality traits, and diverting a percentage of all candidates into any \u201creasonable accommodation\u201d to avoid stigmatizing disability.-UpTurn Newsletter",
        "year": "2020",
        "domain": "Business",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.889248",
        "lng": "-77.050636",
        "is_good": "Harmful",
        "link": "https://cdt.org/insights/report-algorithm-driven-hiring-tools-innovative-recruitment-or-expedited-disability-discrimination/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "How to Talk When a Machine is Listening: Corporate Disclosure in the Age of AI",
        "issue": "How corporations that expect algorithmic processing of their regulatory filings manipulate the text of their filings \u201cto induce algorithmic readers to draw favorable conclusions about the content.\u201d They do so by reducing their use of words with \u201cnegative sentiment\u201d found in dictionaries used to train natural language processing algorithms - UpTurn Newsletter",
        "year": "2020",
        "domain": "Business",
        "city": "Atlanta",
        "state": "Georgia",
        "country": "USA",
        "lat": "33.753746",
        "lng": "-84.38633",
        "is_good": "Harmful",
        "link": "https://www.nber.org/papers/w27950",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Pasco\u2019s sheriff uses grades and abuse histories to label schoolchildren potential criminals.",
        "issue": "Pasco County Sheriff\u2019s Office has a system to identify students \u201cdestined to a life of crime.\u201d The Sheriff\u2019s Office combines its own data with data from the school district and the state\u2019s Department of Children and Families to create a list of kids deemed \u201cat risk.\u201d The system relies on 16 categories including grades, attendance, custody disputes, and abuse and other trauma to label kids most in need of support as likely \u201ccriminals.\u201d. - UpTurn Newsletter",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Pasco",
        "state": "Florida",
        "country": "USA",
        "lat": "28.189453",
        "lng": "-82.349396",
        "is_good": "Harmful",
        "link": "https://projects.tampabay.com/projects/2020/investigations/police-pasco-sheriff-targeted/school-data/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "DeepMind\u2019s protein-folding AI has solved a 50-year-old grand challenge of biology",
        "issue": "AlphaFold can predict the shape of proteins to within the width of an atom. The breakthrough will help scientists design drugs and understand disease.",
        "year": "2020",
        "domain": "Health Services",
        "city": "",
        "state": "London",
        "country": "England",
        "lat": "51.007351",
        "lng": "-0.107758",
        "is_good": "Helpful",
        "link": "https://www.technologyreview.com/2020/11/30/1012712/deepmind-protein-folding-ai-solved-biology-science-drugs-disease/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Can an Algorithm Tell When Kids Are in Danger?",
        "issue": "Allegheny County's Office of Children, Youth and Families uses a predictive analytics algorithm to help screeners identify the families in most need of intervention. The algorithm is \"is less bad at weighing biases than human screeners have been\" and has a 90 percent accuracy of predicting bad outcomes of families.",
        "year": "2018",
        "domain": "Society",
        "city": "Pittsburgh",
        "state": "Pennsylvania",
        "country": "USA",
        "lat": "40.442169",
        "lng": "-79.994957",
        "is_good": "Helpful",
        "link": "https://www.nytimes.com/2018/01/02/magazine/can-an-algorithm-tell-when-kids-are-in-danger.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Can an Algorithm Prevent Suicide?",
        "issue": "The Department of Veterans Affairs has turned to machine-learning to help identify vets at risk of taking their own lives. -NY Times",
        "year": "2020",
        "domain": "Health Services",
        "city": "New York City",
        "state": "New York",
        "country": "USA",
        "lat": "40.826149",
        "lng": "-73.920273",
        "is_good": "Helpful",
        "link": "https://www.nytimes.com/2020/11/23/health/artificial-intelligence-veterans-suicide.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Microsoft\u2019s new image-captioning AI will help accessibility in Word, Outlook, and beyond",
        "issue": "Microsoft's new image captioning algorithm is able to outperform humans in some tests. The system will be used in Microsoft's Seeing AI, an assistant app for the visually impaired, and other products such as Word, Outlook, and Powerpoint. It can be used to generate alt-text for images which will increase accessibility.",
        "year": "2020",
        "domain": "Business",
        "city": "Seattle",
        "state": "Washington",
        "country": "USA",
        "lat": "47.6062",
        "lng": "-122.335167",
        "is_good": "Helpful",
        "link": "https://www.theverge.com/2020/10/14/21514405/image-captioning-seeing-ai-microsoft-algorithm-word-powerpoint-outlook",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google\u2019s AI flood warnings now cover all of India and have expanded to Bangladesh",
        "issue": "Google uses machine learning to alert users in India that a flood may occur in their location. With the expansion, \"200 million people in India and 40 million people in Bangladesh can now receive alerts from its flood forecasting system.\"",
        "year": "2020",
        "domain": "Society",
        "city": "",
        "state": "",
        "country": "India",
        "lat": "20.593683",
        "lng": "78.962883",
        "is_good": "Helpful",
        "link": "https://www.theverge.com/2020/9/1/21410252/google-ai-flood-warnings-india-bangladesh-coverage-prediction",
        "action": "",
        "reading": "",
        "insights": ""
    },
        {
        "title": "The Death and Life of an Admissions Algorithm",
        "issue": "University Texas at Austin has stopped using a machine-learning system GRADE to evaluate applicants for its Ph.D. in computer science. Critics say the system exacerbates existing inequality in the field.\n",
        "year": "2020",
        "domain": "Education",
        "city": "Austin",
        "state": "Texas",
        "country": "USA",
        "lat": "30.267153",
        "lng": "-97.743057",
        "is_good": "Harmful",
        "link": "https://www.insidehighered.com/admissions/article/2020/12/14/u-texas-will-stop-using-controversial-algorithm-evaluate-phd",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Clearview AI\u2019s Facial Recognition App Called Illegal in Canada",
        "issue": "Canadian authorities declared that Clearview AI needed consent to use their biometric information and to delete facial images from its database. \"What Clearview does it mass surveillance, and it is illegal\" says Commissioner Daniel Therrien.",
        "year": "2021",
        "domain": "Law Enforcement",
        "city": "",
        "state": "",
        "country": "Canada",
        "lat": "56.130367",
        "lng": "-106.346771",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2021/02/03/technology/clearview-ai-illegal-canada.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Police Say They Can Use Facial Recognition, Despite Bans",
        "issue": "More than a dozen cities have passed facial recognition bans in the past couple of years. But all these laws have language in their regulations that may allow local police to continue using facial recognition through state and federal agencies or the private sector.",
        "year": "2021",
        "domain": "Law Enforcement",
        "city": "",
        "state": "",
        "country": "USA",
        "lat": "37.09024",
        "lng": "-95.712891",
        "is_good": "Harmful",
        "link": "https://themarkup.org/news/2021/01/28/police-say-they-can-use-facial-recognition-despite-bans",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Job Screening Service Halts Facial Analysis of Applicants",
        "issue": "HireVue, a leading provider of software for vetting job candidates based on an algorithmic assessment is killing off a controversial feature of its software: analyzing a person\u2019s facial expressions in a video to discern certain characteristics. Some experts say that the underlying science of using facial analysis to determine personality/emotion is flawed.",
        "year": "2021",
        "domain": "Business",
        "city": "South Jordan",
        "state": "Utah",
        "country": "USA",
        "lat": "40.559311",
        "lng": "-111.938667",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/job-screening-service-halts-facial-analysis-applicants/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "LAPD panel approves new oversight of facial recognition, rejects calls to end program",
        "issue": "The Los Angeles Police Commission approved a policy Tuesday that set new parameters on the LAPD\u2019s use of facial recognition technology, but stopped far short of the outright ban sought by many city activists.",
        "year": "2021",
        "domain": "Law Enforcement",
        "city": "Los Angeles",
        "state": "California",
        "country": "USA",
        "lat": "34.052235",
        "lng": "-118.243683",
        "is_good": "Harmful",
        "link": "https://www.latimes.com/california/story/2021-01-12/lapd-panel-approves-new-oversight-of-facial-recognition-rejects-calls-to-end-program",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "National Defence skirted federal rules in using artificial intelligence, privacy commissioner says",
        "issue": "The Department of National Defence tested the use of artificial intelligence last year in an effort to improve diversity in the workplace, but the project was run outside of federal rules aimed at ensuring that the technology is used responsibly.",
        "year": "2021",
        "domain": "Law Enforcement",
        "city": "",
        "state": "",
        "country": "Canada",
        "lat": "56.130367",
        "lng": "-106.346771",
        "is_good": "Harmful",
        "link": "\nhttps://www.theglobeandmail.com/canada/article-national-defence-skirted-federal-rules-in-using-artificial/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Deliveroo Rating Algorithm Was Unfair To Riders, Italian Court Rules",
        "issue": "An Italian court ruled that an algorithm once used by Deliveroo to assess riders on its platform was discriminatory. The ruling found that the algorithm was in violation of labor laws because it did not differentiate between the reasons a rider may have for not working.",
        "year": "2021",
        "domain": "Business",
        "city": "",
        "state": "",
        "country": "Italy",
        "lat": "41.871941",
        "lng": "12.56738",
        "is_good": "Harmful",
        "link": "https://www.forbes.com/sites/jonathankeane/2021/01/05/italian-court-finds-deliveroo-rating-algorithm-was-unfair-to-riders/?sh=52ab09b822a1",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook\u2019s ad tools subsidize partisanship, research shows. And campaigns may not even know it.",
        "issue": "The technologies Facebook uses to show advertisements may be more responsible for the polarization of American politics than previously understood, a team of researchers has concluded. By slotting liberal ads to liberal users and conservative ads to conservative users, the study warns, Facebook is \u201cwielding significant power over political discourse through its ad delivery algorithms without public accountability or scrutiny.\u201d",
        "year": "2021",
        "domain": "Society",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Harmful",
        "link": "https://www.washingtonpost.com/technology/2019/12/10/facebooks-ad-delivery-system-drives-partisanship-even-if-campaigns-dont-want-it-new-research-shows/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Apple\u2019s Adult Content Filter Blocks Anything \u2018Asian.\u2019 And \u2018Daddy\u2019?",
        "issue": "Apple\u2019s flawed adult content filters are blocking innocuous sites while letting actual pornography through.",
        "year": "2021",
        "domain": "Business",
        "city": "Cupertino",
        "state": "California ",
        "country": "USA",
        "lat": "37.323",
        "lng": "-122.0322",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en/article/3aneb3/the-iphone-is-smart-so-why-does-it-suck-at-filtering-porn",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI Chatbot Shut Down After Learning to Talk Like a Racist Asshole",
        "issue": "A social media-based chatbot developed by a South Korean startup was shut down on Tuesday after users complained that it was spewing vulgarities and hate speech.",
        "year": "2021",
        "domain": "Business",
        "city": "Seoul",
        "state": "",
        "country": "Korea",
        "lat": "37.566536",
        "lng": "126.977966",
        "is_good": "Harmful",
        "link": "https://www.vice.com/amp/en/article/akd4g5/ai-chatbot-shut-down-after-learning-to-talk-like-a-racist-asshole?__twitter_impression=true",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "ExamSoft proctoring software has a face-detection problem",
        "issue": "ExamSoft's proctoring software is having a problem with recognizing faces, which could delay or bar test takers. According to reports, this software disproportionately impacts people with dark skin tones. ",
        "year": "2021",
        "domain": "Society",
        "city": "Dallas",
        "state": "Texas",
        "country": "USA",
        "lat": "32.776271",
        "lng": "-96.796855",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/2021/1/5/22215727/examsoft-online-exams-testing-facial-recognition-report",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Outlandish Stanford facial recognition study claims there are links between facial features and political orientation",
        "issue": "A paper published today in the journal Scientific Reports by controversial Stanford-affiliated researcher Michal Kosinski claims to show that facial recognition algorithms can expose people\u2019s political views from their social media profiles.",
        "year": "2021",
        "domain": "Society",
        "city": "Stanford",
        "state": "California",
        "country": "USA",
        "lat": "37.42654",
        "lng": "-122.170292",
        "is_good": "Harmful",
        "link": "https://venturebeat.com/2021/01/11/outlandish-stanford-facial-recognition-study-claims-there-are-links-between-facial-features-and-political-orientation/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Broken promises: How Singapore lost trust on contact tracing privacy",
        "issue": "Data collected by TraceTogether, a Covid-19 tracing app in Singapore, could be accessed and used by the police for criminal investigations. This contradicts the Singaporean government's claim that data collected would only be used for contact tracing.",
        "year": "2021",
        "domain": "Law Enforcement",
        "city": "",
        "state": "",
        "country": "Singapore",
        "lat": "1.357107",
        "lng": "103.819499",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2021/01/11/1016004/singapore-tracetogether-contact-tracing-police/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Chinese tech patents tools that can detect, track Uighurs",
        "issue": " Chinese technology giants have registered patents for tools that can detect, track and monitor Uighurs in a move human rights groups fear could entrench oppression of the Muslim minority.",
        "year": "2021",
        "domain": "Society",
        "city": "Xinjiang",
        "state": "",
        "country": "China",
        "lat": "42.480495",
        "lng": "85.463346",
        "is_good": "Harmful",
        "link": "https://news.trust.org/item/20210113195157-jq6lj/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "A scary proposal to use facial recognition and AI by an Indian state has experts fuming",
        "issue": "Police in Lucknow are installing cameras that have facial recognition technology that will gauge whether a woman is in distress based on her facial expression and send an alert to the police, even before anyone can call the police for help. Most experts consider this surveillance program precarious.",
        "year": "2021",
        "domain": "Law Enforcement",
        "city": "Lucknow",
        "state": "",
        "country": "India",
        "lat": "26.8381",
        "lng": "80.9346001",
        "is_good": "Harmful",
        "link": "https://www.businessinsider.in/news/a-scary-proposal-to-use-facial-recognition-and-ai-by-an-indian-state-has-experts-fuming/articleshow/80421935.cms",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Amnesty International calls for a ban on facial recognition in New York City",
        "issue": "Amnesty International has launched a new campaign against facial recognition and launching with a demand for New York City to halt police and government use of the technology. Amnesty argues facial recognition is incompatible with basic privacy rights, and will exacerbate structural racism in policing tactics. ",
        "year": "2021",
        "domain": "Law Enforcement",
        "city": "New York City",
        "state": "New York",
        "country": "USA",
        "lat": "40.7128",
        "lng": "-74.006",
        "is_good": "Harmful",
        "link": "https://www.theverge.com/2021/1/25/22248849/new-york-city-facial-recognition-ban-amnesty-international-nypd",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "This Site Published Every Face From Parler's Capitol Riot Videos",
        "issue": "Faces of the Riot used open source software to detect, extract, and deduplicate every face from the 827 videos taken from the insurrection on January 6.  Faces of the Riot represents the serious privacy dangers of pervasive facial recognition technology, says Evan Greer, the campaign director for digital civil liberties nonprofit Fight for the Future",
        "year": "2021",
        "domain": "Society",
        "city": "",
        "state": "Washington DC",
        "country": "USA",
        "lat": "38.889248",
        "lng": "-77.050636",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/faces-of-the-riot-capitol-insurrection-facial-recognition/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "University of Washington researchers say Amazon\u2019s algorithms spread vaccine misinformation",
        "issue": "According to the University of Washington researchers, their audits reveal Amazon hosts a \u201cplethora\u201d of health misinformative products\". Several medically unverified products for coronavirus treatment, like prayer healing, herbal treatments and antiviral vitamin supplements proliferated Amazon, so much so that the company had to remove 1 million fake products after several instances of such treatments were reported by the media,\u201d the researchers wrote in their paper. ",
        "year": "2021",
        "domain": "Business",
        "city": "Seattle",
        "state": "Washington",
        "country": "USA",
        "lat": "47.606209",
        "lng": "-122.332069",
        "is_good": "Harmful",
        "link": "https://venturebeat.com/2021/01/26/university-of-washington-researchers-say-amazons-algorithms-spread-vaccine-misinformation/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Facebook Executives Shut Down Efforts to Make the Site Less Divisive",
        "issue": "Facebook have has shut down its research to understand how the platform shaped user behaviour and to how address the platform's potential polarization of its users.",
        "year": "2020",
        "domain": "Society",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Helpful",
        "link": "https://www.wsj.com/articles/facebook-knows-it-encourages-division-top-executives-nixed-solutions-11590507499",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Why Is Facebook Rejecting These Fashion Ads?",
        "issue": "The automated intelligence systems of Instagram and Facebook have repeatedly denied ads placed by small businesses that make stylish clothing for people with disabilities.",
        "year": "2021",
        "domain": "Society",
        "city": "Menlo Park",
        "state": "California",
        "country": "USA",
        "lat": "37.45322",
        "lng": "-122.18322",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2021/02/11/style/disabled-fashion-facebook-discrimination.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Google targets AI ethics lead Margaret Mitchell after firing Timnit Gebru",
        "issue": "Google has revoked Ethical AI team leader Margaret \u201cMeg\u201d Mitchell\u2019s employee privileges and is currently investigating her activity, according to a statement provided by a company spokesperson. Should Google fire Mitchell, it will mean the company has effectively chosen to behead its own AI ethics team in under two months.",
        "year": "2021",
        "domain": "Society",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://venturebeat.com/2021/01/20/google-targets-ai-ethics-lead-margaret-mitchell-after-firing-timnit-gebru/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "ExamSoft's proctoring software has a face-detection problem",
        "issue": "Companies trying to run ads on YouTube or elsewhere on the web could direct Google not to show those ads to people of \u201cunknown gender\u201d\u2014meaning people who have not identified themselves to Google as \u201cmale\u201d or \u201cfemale.\u201d After being alerted to this by The Markup, Google pledged to crack down on the practice.",
        "year": "2021",
        "domain": "Society",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://themarkup.org/google-the-giant/2021/02/11/google-has-been-allowing-advertisers-to-exclude-nonbinary-people-from-seeing-job-ads",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Wrongfully Arrested Because Face Recognition Can\u2019t Tell Black People Apart",
        "issue": "Robert Williams was arrested by Michigan State Police for thief. However, it was due to facial recognition technology being unable to tell black people apart. ",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Detroit",
        "state": "Michigan",
        "country": "USA",
        "lat": "42.331429",
        "lng": "-83.045753",
        "is_good": "Harmful",
        "link": "https://www.aclu.org/news/privacy-technology/wrongfully-arrested-because-face-recognition-cant-tell-black-people-apart/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "The Best Algorithms Struggle to Recognize Black Faces Equally",
        "issue": "IDEMIA's facial recognition software used by the police in the USA, Australia, and France has made significant mistakes in identifying black women as compared to white women, or black and white men.\n",
        "year": "2019",
        "domain": "Law Enforcement",
        "city": "Courbevoie",
        "state": "",
        "country": "France",
        "lat": "48.89683",
        "lng": "2.25654",
        "is_good": "Harmful",
        "link": "https://www.wired.com/story/best-algorithms-struggle-recognize-black-faces-equally/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Amazon\u2019s facial recognition moratorium has major loopholes",
        "issue": "Amazon suspended contracts involving police use of its Rekognition software for a year most likely in response to the Justice in Policing Act Bill.",
        "year": "2020",
        "domain": "Law Enforcement",
        "city": "Seattle",
        "state": "Washington",
        "country": "USA",
        "lat": "47.606209",
        "lng": "-122.332069",
        "is_good": "Harmful",
        "link": "https://techcrunch.com/2020/06/10/amazon-rekognition-moratorium/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "British Grading Debacle Shows Pitfalls of Automating Government",
        "issue": "After college entrance exams were canceled because of the pandemic, the UK government used an algorithm that determined grades based on students\u2019 past performance. The system reduced the grades of nearly 40% of students and led to accusations that it was biased against test takers from challenging socioeconomic backgrounds.",
        "year": "2020",
        "domain": "Society",
        "city": "London",
        "state": "",
        "country": "England",
        "lat": "51.507351",
        "lng": "-0.127758",
        "is_good": "Harmful",
        "link": "https://www.nytimes.com/2020/08/20/world/europe/uk-england-grading-algorithm.html",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Self-driving cars guided by AI performed worse at detecting people with dark skin",
        "issue": "A study found that self-driving cars guided by AI performed worse at detecting people with dark skin, which could put the lives of dark-skinned pedestrians at risk.",
        "year": "2019",
        "domain": "Society",
        "city": "Atlanta",
        "state": "Georgia",
        "country": "USA",
        "lat": "33.748997",
        "lng": "-84.387985",
        "is_good": "Harmful",
        "link": "https://www.businessinsider.com/self-driving-cars-worse-at-detecting-dark-skin-study-says-2019-3",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Study shows how AI exacerbates recruitment bias against women",
        "issue": "A new study from the University of Melbourne has demonstrated how hiring algorithms can amplify human gender biases against women.",
        "year": "2020",
        "domain": "Business",
        "city": "Melbourne",
        "state": "",
        "country": "Australia",
        "lat": "-37.813629",
        "lng": "144.963058",
        "is_good": "Harmful",
        "link": "https://thenextweb.com/neural/2020/12/02/study-shows-how-ai-exacerbates-recruitment-bias-against-women/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Racial disparities in automated speech recognition",
        "issue": "A study at Stanford found that automated speech recognition systems demonstrate large racial disparities, with voice assistants misidentifying 35% of words from Black users while only misidentifying 19% of words from white users.",
        "year": "2020",
        "domain": "Society",
        "city": "Stanford",
        "state": "California",
        "country": "USA",
        "lat": "40.74189",
        "lng": "-73.9893",
        "is_good": "Harmful",
        "link": "https://www.pnas.org/content/117/14/7684",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "I'd blush if I could: closing gender divides in digital skills through education",
        "issue": "A study by UNESCO \u201dlooks at the impact of having female voice assistants, from Amazon\u2019s Alexa to Apple\u2019s Siri, projected in a way that suggests that women are \u201csubservient and tolerant of poor treatment.\u201d Further, researchers argue that tech companies have failed to take protective measures against abusive or gendered language from users.",
        "year": "2019",
        "domain": "Society",
        "city": "Cupertino",
        "state": "California",
        "country": "USA",
        "lat": "37.323",
        "lng": "-122.0322",
        "is_good": "Harmful",
        "link": "https://unesdoc.unesco.org/ark:/48223/pf0000367416.page=1",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI is wrestling with a replication crisis",
        "issue": "The Nature Journal published a critical response to Google Health AI study. The Google team provided so little information about its code and how it was tested that the study amounted to nothing more than a promotion of proprietary tech. There's a growing number of scientists pushing back against a perceived lack of transparency in AI research.",
        "year": "2020",
        "domain": "Business",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Harmful",
        "link": "https://www.technologyreview.com/2020/11/12/1011944/artificial-intelligence-replication-crisis-science-big-tech-google-deepmind-facebook-openai/",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Twitter Accounts With AI-Generated Cat Avatars at Center of Turkish Porn Bot Ring",
        "issue": "Twitter has suspended a bot network made up of accounts that have unique avatars featuring AI-generated women, anime characters, and cats. These bots have GAN-generated profile pictures and advertise incessantly in Turkish about porn and sports betting.",
        "year": "2021",
        "domain": "Business",
        "city": "San Francisco",
        "state": "California",
        "country": "USA",
        "lat": "37.7765",
        "lng": "-122.4172",
        "is_good": "Harmful",
        "link": "https://www.vice.com/en/article/z3v579/twitter-accounts-with-ai-generated-cat-avatars-at-center-of-turkish-porn-bot-ring",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "This Japanese AI Journaling App Helped Me Understand My Feelings",
        "issue": "An first-person narrative from a VICE contributor on how Muute, Japan\u2019s first AI journaling app, helpeed her reflect on her emotions. ",
        "year": "2021",
        "domain": "Society",
        "city": "Tokyo",
        "state": "",
        "country": "Japan",
        "lat": "35.689487",
        "lng": "139.691711",
        "is_good": "Helpful",
        "link": "https://www.vice.com/en/article/epdk3j/japan-first-ai-journaling-app-muute-experience-understand-feelings-mental-health",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "Tech Companies Want AI to Fix the Disastrous Vaccine Rollout",
        "issue": "As people struggle to book appointments and doses go unused, some companies are proposing automated tools to help deliver vaccines into \narms.",
        "year": "2021",
        "domain": "Health Services",
        "city": "Mountain View",
        "state": "California",
        "country": "USA",
        "lat": "37.386051",
        "lng": "-122.083855",
        "is_good": "Helpful",
        "link": "https://www.vice.com/en/article/5dp4nz/tech-companies-want-ai-to-fix-the-disastrous-vaccine-rollout",
        "action": "",
        "reading": "",
        "insights": ""
    },
    {
        "title": "AI could make health care fairer\u2014by helping us believe what patients say",
        "issue": "A new study shows how training deep-learning models on patient outcomes could help reveal gaps in existing medical knowledge. In the study, an AI model was much more accurate than radiologists' KLG system at predicting self-reported pain levels for both white and Black patients, but especially for Black patients. It reduced the racial disparity at each pain level by nearly half.",
        "year": "2021",
        "domain": "Health Services",
        "city": "",
        "state": "California",
        "country": "USA",
        "lat": "36.778259",
        "lng": "-119.417931",
        "is_good": "Helpful",
        "link": "https://www.technologyreview.com/2021/01/22/1016577/ai-fairer-healthcare-patient-outcomes/",
        "action": "",
        "reading": "",
        "insights": ""
    }
]
